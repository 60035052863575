import React from "react";
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  FlatList,
  TouchableOpacity
} from "react-native";
import Header from "../SharedComponents/Header";
import { COLORS, FONT_FAMILY, STYLES, TEXT_STYLES } from "../Util/Styles";
import config from "../Util/config";
import UserContext from "../Context/UserContext";
import timeTransfer from "../Util/timeTransfer";
import axios from "axios";
import moment from "moment";

export default class OrderHistoryPage extends React.Component {
  static contextType=UserContext

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      isLoading: false,
    }
  }
  handleOrderDetail = (item) => {

    this.props.navigation.navigate('orderDetail',{
      orderId: item.orderId,
      item: item,
    });
  };
  componentDidMount = () => {
    this.loadData()
    console.log('stateData',this.state.data);
  };

  parseEventData = (data) => {
    let eventData = [];
    for (let i = 0; i < data.length; i++) {
      let eventItem
      eventItem = {
        _id: data[i]._id,
        status: data[i].status==='takedeposit'?'deposit':data[i].status,
        email: data[i].email ? data[i].email : "",
        phone: data[i].phone ? data[i].phone : "",
        userId: data[i].userId ? data[i].userId : "",
        customerName: data[i].username?data[i].username:'',
        employee: data[i].selectedEmployee && data[i].selectedEmployee.name ? data[i].selectedEmployee.name : "",
        selectedService:data[i].type=='blocked'?'Blocked Time': data[i].selectedService.name,
        room: (data[i].selectedService.RoomDoc && data[i].selectedService.RoomDoc.length > 0)
          ? data[i].selectedService.RoomDoc[0].name
          : "",
        message: data[i].message,
        type:data[i].type?data[i].type:'',
        orderId: data[i].orderId,
        duration: data[i].duration,
        startTime: moment(data[i].start).format("HH:mm"),
        endTime: moment(data[i].end).format("HH:mm"),
        day: moment(data[i].day).format("dddd, MMMM, DD, YYYY"),
        shopAddress: data[i].shopAddress,
        adminid: data[i].shopId._id,
        shopName:data[i].shopId.shopName,
      };
      
      eventData.push(eventItem);
    }
    return {
      eventData,
    };
  };

  loadData(isRefresh = false) {
    const {user, setUser}=this.context
    console.log('refreshData1',this.state.data);
    if (!this.state.isLoading)
      this.setState({
        isLoading: true
      }, () => {
        let skiplength = isRefresh ? 0 : this.state.data.length
        axios
          .post(config.apiurl + "/employee/getHistoryEmployeeEvents", {
            employeeId: user._id,
            endDate: new Date().setHours(0, 0, 0, 0),
            length: skiplength
          })
          .then((res) => {
            this.setState({
              isLoading: false,
            })

            if (res.data.ok) {
              console.log('resData',res.data);
              if (this.state.data.length == 0) {
                this.setState({ data: this.parseEventData(res.data.data).eventData })
              } else {
                
                const copyData = Array.from(new Set(this.state.data.concat(this.parseEventData(res.data.data).eventData).map(JSON.stringify))).map(JSON.parse);           
                this.setState({ data: copyData })
              }
            }
            return [];
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
            })

          });
      })
  }
  renderItem = ({ item, index }) => {
    const {
      status,
      customerName,
      day,
      endTime,
      room,
      userId,
      shopName,
      selectedService,
      startTime,
      message,
      orderId,
      type,
    } = item;
    return (
    <View style={styles.container} key={item._id}>
      <View style={styles.titleContainer}>
        <TouchableOpacity onPress={() => this.handleOrderDetail(item)}>
          <View>
            <Text style={styles.orderIdStyle}>{orderId}</Text>
          </View>
        </TouchableOpacity>
        {status && status.length > 0 ? (
          <View style={styles.statusContainer}>

            <Text style={{color: "#ea9b8e"}}>{status}</Text>
          </View>
        ) : null}
      </View>
      <View style={{ flexDirection: "column", alignSelf: "flex-start" }}>
        <View style={styles.subContainer}>
          <Text style={styles.subTitleText}>Time</Text>
          <View>
            <Text>{day}</Text>
            <Text>
              {timeTransfer(startTime) }-{timeTransfer(endTime) } {room}
            </Text>
          </View>
        </View>
        <View style={styles.subContainer}>
          <Text style={styles.subTitleText}>Service</Text>
          <Text>{selectedService} </Text>
        </View>
        {item.type!='blocked'?
        <View style={styles.subContainer}>          
        <Text style={styles.subTitleText}>Name</Text>
        <TouchableOpacity onPress={() => {
         this.props.navigation.navigate('userDetail',{
            userId: item.userId,
            customerName: item.customerName,
            phone: item.phone,
            email: item.email
          })
        }}>
          <Text style={{ textDecorationLine: "underline", color: "#4169E1" }}>{customerName}</Text>
        </TouchableOpacity>
        </View>:null
        }
        
        {message ? (
          <View style={styles.subContainer}>
            <Text style={styles.subTitleText}>Note</Text>
            <Text>{message}</Text>
          </View>
        ) : null}
        <View style={styles.subContainer}>
          <Text style={styles.subTitleText}>Shop</Text>
          <Text>{shopName} </Text>
        </View>
      </View>      

    </View>)
  }
  renderEmptyComponent = () => {
    return (
      <View>
        <Text style={styles.emptyText}>
          No results
        </Text>
      </View>
    )
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <Header/>
        <Text style={STYLES.TAB_TITLE}>History Appointments</Text>
        <View style={STYLES.TAB_TITLE_DIVIDER} />
        <FlatList
          style={{ backgroundColor: COLORS.GRAY_1 }}
          data={this.state.data}
          renderItem={this.renderItem}
          keyExtractor={(item) => item._id}
          showsVerticalScrollIndicator={false}
          ListEmptyComponent={this.renderEmptyComponent}

          //下拉刷新
          refreshing={this.state.isLoading}
          onRefresh={() => {
            this.setState({data:[]})
            this.loadData(true);
          }}
          // onEndReached={() => {
          //   if (!this.state.isLoading)
          //     this.loadData()
          // }}
          onEndReachedThreshold={0.2}
        />
      </SafeAreaView>

    );
  }
}

const styles = StyleSheet.create({

  container:{
    backgroundColor: "white",
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginBottom: 20,
    marginHorizontal: 10,
    borderRadius: 4,
    shadowColor: "rgba(36, 36, 36, 0.05)",
    shadowOffset: {
      width: 0,
      height: 5
    },
    shadowRadius: 15,
    shadowOpacity: 1
  },

  titleContainer:{
    flexDirection: "row",
    alignContent: "space-between",
    justifyContent: "space-between"
  },

  statusContainer:{
    padding: 8,
    borderRadius: 2,
    backgroundColor: "#ffffff",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ea9b8e"
  },

  subContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 2,
    alignContent: "center",
    alignItems: "center",
  },
   
  subTitleText: {
    ...TEXT_STYLES.FONT_TYPE_5,
    ...{
      textAlign: "left",
      width: 60,
    },
  },

  emptyText:{
    fontFamily: FONT_FAMILY,
    alignSelf: 'center',
    marginTop: 100,
    color: 'rgb(74,74,74)'
  },
  orderIdStyle:{
    fontFamily: FONT_FAMILY,
    color: "#007BC3",
    // fontWeight: "bold",
    textAlign: "left",
    textDecorationLine: 'underline',
    fontSize: 20
  }
  
});

