import { LogBox } from 'react-native'
let config = null

export default config = {
  debug: true,
  version: "0.0.1",
  // apiurl: "http://localhost:3001",
  // apiurl: "http://192.168.1.245:3001",
  // apiurl:'http://api.111789.xyz'
  // apiurl: "http://192.168.0.109:3001",
  // apiurl: "http://134.122.34.145:3011",
  // apiurl: "http://192.168.0.192:3011",
  // apiurl: "http://192.168.0.16:3011",
  // apiurl: "http://192.168.0.109:3011",
};
// if (__DEV__) {
     config.apiurl = 'https://back.hrbooked.com'
// }
// LogBox.ignoreAllLogs() // 关闭全部黄色警告

