import React, { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from "@react-navigation/stack";
import LoadingContext from "./src/Context/LoadingContext";
import UserContext from './src/Context/UserContext';
import * as Updates  from 'expo-updates';
import { Platform } from 'react-native';

import Main from "./src/Main";
import Login from "./src/StandAlonePages/Login";
import Booking from './src/StandAlonePages/Booking';
import AppointmentDetail from './src/StandAlonePages/AppointmentDetail'
import OrderHistoryPage from './src/StandAlonePages/OrderHistoryPage';
import Message from "./src/StandAlonePages/Message";
import UserDetailPage from './src/StandAlonePages/UserDetailPage';
import OrderDetailPage from './src/StandAlonePages/OrderDetailPage';
import AccountSetting from './src/StandAlonePages/AccountSetting';
import EditProfile from './src/StandAlonePages/EditProfile';
import Schedule from './src/StandAlonePages/Schedule';
import AddProduct from "./src/StandAlonePages/AddProduct";
import AddService from './src/StandAlonePages/AddService';
// import Scan from './src/StandAlonePages/Scan';



const Stack = createStackNavigator()
import Loading from './src/Util/loading';

const App = () => {
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function updateApp() {
      try {
        const update = await Updates.checkForUpdateAsync();
        if (update.isAvailable) {
          await Updates.fetchUpdateAsync();
          await Updates.reloadAsync();
        }
      } catch (e) {
        console.error(e);
      }
    }
    if (Platform.OS != "web") {
      updateApp();
    }
  }, []);

  const showLoading = () => {
    setLoadingVisible(true);
  };

  const closeLoading = () => {
    setLoadingVisible(false);
  };
  //热更新目前未使用，以后需要恢复
  /*  setInterval(() => {
     try {
       codePush.sync({
         updateDialog: true,
         installMode: codePush.InstallMode.IMMEDIATE
       });
     } catch (error) {
       console.log(error);
     }
   }, 60000) */

  return (
    <LoadingContext.Provider value={{ showLoading, closeLoading }}>
      <UserContext.Provider value={{ user, setUser }}>
        <NavigationContainer>
          <Stack.Navigator screenOptions={{
            cardStyle: {
              backgroundColor: '#ffffff'
            }
          }}
          >
            <Stack.Screen name='Main' options={{ headerShown: false }} component={Main} />
            <Stack.Screen name='Login' options={{ headerShown: false }} component={Login} />
            <Stack.Screen name='Booking' options={{ headerShown: false }} component={Booking} />
            <Stack.Screen name="appointmentDetail" options={{ headerShown: false }} component={AppointmentDetail} />
            <Stack.Screen name="myOrders" options={{ headerShown: false }} component={OrderHistoryPage} />
            <Stack.Screen name="userDetail" options={{ headerShown: false }} component={UserDetailPage} />
            <Stack.Screen name="orderDetail" options={{ headerShown: false }} component={OrderDetailPage} />
            <Stack.Screen name="message" options={{ headerShown: false }} component={Message} />
            <Stack.Screen name="accountSetting" options={{ title: 'Account Setting' }} component={AccountSetting} />
            <Stack.Screen name="editProfile" options={{ title: 'Edit Profile' }} component={EditProfile} />
            <Stack.Screen name="schedule" options={{ headerShown: false }} component={Schedule} />
            <Stack.Screen name="addProduct" options={{ headerShown: false }} component={AddProduct} />
            <Stack.Screen name="addService" options={{ headerShown: false }} component={AddService} />
            {/* <Stack.Screen name="scan" options={{ headerShown: false}} component={Scan} /> */}



          </Stack.Navigator>
        </NavigationContainer>
        <Loading hide={!loadingVisible} />
      </UserContext.Provider>
    </LoadingContext.Provider>

  );
}

//热更新目前未使用，以后需要恢复
/* export default codePush({
  installMode: codePush.InstallMode.IMMEDIATE,
  checkFrequency: codePush.CheckFrequency.ON_APP_RESUME
})(App); */

export default App
