import React, { useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  Alert,
  TouchableOpacity,
  DeviceEventEmitter
} from "react-native";
import Header from "../SharedComponents/Header";
import { COLORS, FONT_FAMILY, STYLES, TEXT_STYLES } from "../Util/Styles";
import axios from "axios";
import { API } from "../API";
import config from "../Util/config";
import { PickerButton, PickerContent } from "../Components/Picker";
import { RationButton } from "../Components/Button";
import UserContext from "../Context/UserContext";

export default class AddService extends React.Component {
  static contextType=UserContext
  constructor(props) {
    super(props);
    this.state = {
      selectedService: null,
      fetchingAllServiceData: false,
      fetchingAllHairdresserData: false,
      selectedEmployee: null,
      servicePickerVisible: false,
      hairdresserPickerVisible: false,
      serviceData: [],
      quickAddServiceData:[],
      orderId: this.props.route.params.orderId,
      order_id:'',
      adminId:this.props.route.params.adminId,
      serviceBondEmployees:[],
      ratioButtonSelected:false
    };
  }
  componentDidMount = () => {
    API.getAllServicesByAdminID(
      this.state.adminId,
      (serviceData) => {
        let quickAddServiceData=serviceData.data.filter(data=>data.isQuickAdd)
        console.log('quickAddServiceData',quickAddServiceData);
        this.setState({
          serviceData: serviceData.data,
          fetchingAllServiceData: false,
          quickAddServiceData:quickAddServiceData
        });
      },
      () => {
        // handle api error
        this.setState({ fetchingAllServiceData: false });
      }
    );
    API.getSingleOrder(this.state.orderId,(res)=>{this.setState({order_id:res.data.data._id})})
  };
  
  servicePickerOnConfirm = (selectedService) => {
    const {user}=this.context
    this.setState({
      servicePickerVisible: false,
      selectedService: selectedService,
    });
       
    /* let serviceBondEmployees = []
   
   API.getEmployeeByService(selectedService._id,this.state.adminId,(res)=>{
    for(let i=0;i<res.data.length;i++){
      serviceBondEmployees.push({
        employee:res.data[i].employee[0],
        duration:res.data[i].duration,
        startDuration:res.data[i].startDuration,
        processingDuration:res.data[i].processingDuration,
        finishDuration:res.data[i].finishDuration,
        price:res.data[i].price
      }) 
    }
    console.log('serviceBondEmployees',serviceBondEmployees);
    this.setState({serviceBondEmployees:serviceBondEmployees})
   },
   ()=>{
    this.setState({
      serviceBondEmployees: []
    })
   })       */
  };


  employeePickerOnConfirm = (selectedEmployee) => {
    this.setState({
      selectedEmployee: selectedEmployee,
      hairdresserPickerVisible: false,
    });
  };
  renderServiceItem = (item) => (
    <View style={styles.pickerItemBG}>
      <Text style={styles.pickerItemText}>{item.name}</Text>
    </View>
  );
  renderSelectedServiceItem = (item) => (
    <View style={styles.pickerSelectedItemBG}>
      <Text style={styles.pickerItemText}>{item.name}</Text>
    </View>
  );

  submitOnPress = () => {
    if (!this.state.selectedService) {
      Alert.alert("Please select service!");
      return
    }
    let selectedService=this.state.selectedService
    let addTime = new Date()    
    let endTime = new Date(addTime.getTime() + parseInt(selectedService.duration) * 60 * 1000);
    console.log("serviceAppend_onOk addTime:",addTime.toUTCString())

    let newEvent = {
        shopId: this.state.adminId,       
        duration: selectedService.duration,
        startDuration: selectedService.startDuration,
        processingDuration: selectedService.processingDuration,
        finishDuration:selectedService.finishDuration,
        price:selectedService.price,
        serviceType: "appendService",
        day: addTime.toUTCString(),
        start: addTime.toUTCString(),
        end:  endTime.toUTCString(),
    }
    
    var postData = {
        order_id: this.state.order_id,
        serviceId: this.state.selectedService._id,
        event: newEvent,
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    }

    axios
        .post(`${config.apiurl}/order/addOrderServiceEvent`, postData, axiosConfig)
        .then(res => {
            if (res.status === 200) {
                console.log('success');
                DeviceEventEmitter.emit('refreshAppointment')
                this.props.navigation.goBack()
            } else {
                console.log(res)
            }
        })
        .catch(err => {
            console.log(err)
        })
  };

  ratioButtonOnPress=()=>{
    this.setState({ratioButtonSelected:!this.state.ratioButtonSelected},()=>{console.log('ratioButtonSelected',this.state.ratioButtonSelected);})
  }


  render() {
    return (
      <View style={styles.container}>

        <Header />
        <Text style={STYLES.TAB_TITLE}>Add Service</Text>
        <View style={STYLES.TAB_TITLE_DIVIDER} />
        <RationButton
          text='Quick Add'
          onPress={this.ratioButtonOnPress}
          style={{marginLeft:20,marginTop:20}}
          selected={this.state.ratioButtonSelected}
        />
        <PickerButton
          pickerTitle={"Service"}
          require={true}
          placeHolder={"Select a Service"}
          selected={this.state.selectedService}
          containerStyle={styles.servicePicker}
          onPress={() => {
            this.setState({ servicePickerVisible: true });
          }}
          isLoading={this.state.fetchingAllServiceData}
          isEmpty={(service) => service === null}
          background={2}
          getDisplayText={(item) => item.name}
        />
        
       {/*  <PickerButton
          pickerTitle={"Stylist"}
          require={false}
          placeHolder={"Select a Stylist"}
          selected={this.state.selectedEmployee}
          containerStyle={styles.servicePicker}
          onPress={() => {
            this.setState({ hairdresserPickerVisible: true });
          }}
          isLoading={this.state.fetchingAllHairdresserData}
          isEmpty={(item) => item === null}
          background={2}
          getDisplayText={(item) => item.employee.name }
          invisible={this.state.selectedService === null}
        /> */}
        
        <TouchableOpacity
          style={{
            backgroundColor: COLORS.MAIN,
            borderRadius: 30,
            borderWidth: 1,
            marginTop: 100,
            marginLeft:'auto',                   
            marginRight:'auto',                   
            width: 130,
            height: 40,
            justifyContent: "center",
            borderColor: "rgba(0,0,0,0)",
          }}
          onPress={this.submitOnPress}
        >
          <Text
            style={{
              fontFamily: FONT_FAMILY,
              fontSize: 19,
              marginHorizontal: 20,
              textAlign: "center",
              color: "white",
            }}
          >
            {"Submit"}
          </Text>
        </TouchableOpacity>

        <PickerContent
          onConfirm={(selectedService) => {
            this.servicePickerOnConfirm(selectedService);
          }}
          visible={this.state.servicePickerVisible}
          onCancel={() => {
            this.setState({ servicePickerVisible: false });
          }}
          isLoading={this.state.fetchingAllServiceData}
          store={this.state.adminId}
          search={'service'}
          title={"Choose a Service"}
          data={this.state.ratioButtonSelected?this.state.quickAddServiceData: this.state.serviceData}
          selected={this.state.selectedService}
          renderItem={this.renderServiceItem}
          renderSelectedItem={this.renderSelectedServiceItem}
          isItemTheSame={(a, b) =>b? a._id === b._id:false}
          keyExtractor={(item) => item._id}
        />

       {/*  <PickerContent
          onConfirm={(selectedEmployee) => {
            this.employeePickerOnConfirm(selectedEmployee);
          }}
          visible={this.state.hairdresserPickerVisible}
          onCancel={() => {
            this.setState({ hairdresserPickerVisible: false });
          }}
          title={"Choose a Stylist"}
          data={this.state.serviceBondEmployees}
          selected={this.state.selectedEmployee}
          renderItem={(item) => {
            return (
              <View style={styles.pickerItemBG}>
                <Text style={styles.pickerItemText}>{item.employee.name}</Text>
              </View>
            );
          }}
          renderSelectedItem={(item) => {
            return (
              <View style={styles.pickerSelectedItemBG}>
                <Text style={styles.pickerItemText}>{item.employee.name}</Text>
              </View>
            );
          }}
          isItemTheSame={(a, b) => b? a.employee._id === b.employee._id:false
          }
          keyExtractor={(item) => item.employee._id}
        /> */}
        </View>
    );
  }
}

const styles = StyleSheet.create({
  avatar: {
    width: 70,
    height: 70,
    marginLeft: 20,
    marginRight: 16,
    borderRadius: 70,
    backgroundColor: COLORS.MAIN,
    marginTop: 36,
  },

  name: {
    fontFamily: FONT_FAMILY,
    fontSize: 24,
    lineHeight: 29,
    color: COLORS.GRAY_4,
    marginTop: 48,
    marginRight: 20,
  },

  email: {
    fontSize: 16,
    lineHeight: 19,
    marginRight: 20,
  },

  row: {
    paddingVertical: 24,
    paddingLeft: 10,
    paddingRight: 22,
    flexDirection: "row",
    marginHorizontal: 20,
    alignContent: "center",
  },

  rowText: {
    flex: 1,
    fontSize: 16,
    lineHeight: 19,
  },

  icon: {
    marginRight: 12,
  },

  content: {
    flex: 1,
  },

  container: {
    flex: 1,
    // height: Common.SCREEN_WIDTH
  },
  containerPicker: {
    marginTop: 20,
    height: 1000,
    marginHorizontal: 20,
  },

  pickerItemBG: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 18,
    paddingBottom: 16,
  },
  pickerSelectedItemBG: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.TRANSPARENT_GRAY_2,
    paddingTop: 18,
    paddingBottom: 16,
    borderRadius: 34,
  },
  pickerItemText: {
    fontFamily: FONT_FAMILY,
    fontSize: 20,
  },
  servicePicker: {
    marginTop: 20,
    marginHorizontal: 20,
  },
});