import React from "react";

import {SafeAreaView} from "react-native";
import BookingCalendar from "../StandAlonePages/BookingCalendar";
import Header from '../SharedComponents/Header'

const Booking = ({navigation}) => { 
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Header/>
      <BookingCalendar navigation={navigation} />
    </SafeAreaView>
  );
};

export default Booking;
