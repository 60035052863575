import React, { useState, useContext, useEffect } from "react";
import {View,Text,FlatList,StyleSheet,SafeAreaView} from "react-native";
import { Agenda } from "react-native-calendars";
import { Card } from "react-native-paper";
import axios from "axios";
import Moment from "moment";
import UserContext from "../Context/UserContext";
import config from "../Util/config";
import timeTransfer from "../Util/timeTransfer";
import LoadingContext from "../Context/LoadingContext";

const Schedule = ({ navigation }) => {
  const { user } = useContext(UserContext);
  const {showLoading, closeLoading}=useContext(LoadingContext) 

  const [scheduleData, setScheduleData]=useState({})
  const [isRefreshing, setIsRefreshing]=useState(false)

 const loadData=()=> {   
  showLoading()
    axios
      .get(config.apiurl + "/admin/getEmployeeSchedule?id=" + user._id)
      .then((res) => {
        // console.log(res)
        if (res.hasOwnProperty("error")) {
          closeLoading()
          console.log(res);
        } else {
          const item={}
          
          let docs = res.data.docs
          let curDate = new Date()
          curDate.setHours(0, 0, 0, 0)
          let scheduleItem = {}
          for (let i = 0; i < docs.length; i++) {
            if (Moment(curDate).format('YYYYMMDD') <= Moment(docs[i].day).format('YYYYMMDD')) {              

              let date = Moment(docs[i].day).format("YYYY-MM-DD");
              item[date] = [];
              scheduleItem={
                scheduleType: docs[i].scheduleType,
                day: docs[i].day,
                startTime: Moment(docs[i].startTime).format('HH:mm'),
                endTime: Moment(docs[i].endTime).format('HH:mm'),
                launchEnd: Moment(docs[i].launchEnd).format('HH:mm'),
                launchStart: Moment(docs[i].launchStart).format('HH:mm')
              }
              if (item[date]) {
                item[date].push(scheduleItem)
              } else {
                item[date] = [];
                item[date].push(scheduleItem);
              }
            }
          }
          setScheduleData(item)
          closeLoading()
        }
      })
      .catch((err) => {
        closeLoading()

        console.log(err);       
      })
  } 

  useEffect(() => {
    loadData()
  }, []);  

  const renderItem = (item) => {
    return (     
        <Card style={{marginTop: 20}}>
          <Card.Content>            
              <View style={{ flexDirection: "column"}}>  
              <Text style={{fontSize: 18,color: "rgba(0, 0, 0, 0.54)",marginBottom:10}}>
                {Moment(item.day).format('dddd,  MMM DD')}
              </Text>            
              <Text>
                {timeTransfer(item.startTime)}-{timeTransfer(item.endTime)}
              </Text>
              </View>                          
          </Card.Content>
        </Card>      
    );
  };

  const renderEmptyComponent=()=>{
    return(
      <Text style={{ fontSize: 30, alignSelf: "center", flex: 1, margin: 30 }}>
        Day Off
      </Text>
    )
  
  }
 
  const refreshDataFunction=()=>{
    setIsRefreshing(true)
    loadData()
    setIsRefreshing(false)
  }

  return (
    <SafeAreaView style={{ flex: 1,marginTop:50 }}>    
      <Agenda
        items={scheduleData}
        selected={Moment().format("YYYY-MM-DD")}
        minDate={Moment().subtract(1, "y").format("YYYY-MM-DD")}
        maxDate={Moment().add(1, "y").format("YYYY-MM-DD")}
        renderItem={renderItem}
        showClosingKnob={true}
        onRefresh={refreshDataFunction}
        refreshing={isRefreshing} 
        renderEmptyData={() => (
          <FlatList
           ListEmptyComponent={renderEmptyComponent}
           onRefresh={refreshDataFunction}
           refreshing={isRefreshing}
          />                      
        )}
      />
    </SafeAreaView>
  );
};

export default Schedule;