import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  View,
  TextInput,
  Text,
  Image,
  StyleSheet,
  FlatList,
  Alert,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Modal,
  SafeAreaView,
  DeviceEventEmitter,
} from "react-native";
import axios from "axios";
import config from "../Util/config";
import Header from "../SharedComponents/Header";
import Common from "../Util/common";
import {
  SCREEN_WIDTH,
  TEXT_STYLES,
  STYLES,
  COLORS,
  FONT_FAMILY,
  SCREEN_HEIGHT,
} from "../Util/Styles";
export default class AddProduct extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      serviceData: new Map(),
      selectedService: null,
      servicePickerGetDisplayText: "abc",
      fetchingAllServiceData: false,
      fetchingAllHairdresserData: false,
      selectedHairdresser: null,
      isServicePickerEmpty: true,
      servicePickerVisible: false,
      hairdresserPickerVisible: false,
      employeeSelected_Quick: null,
      pickEmployee: [],
      service: [],
      orderId: this.props.route.params.orderId,
      message: "",
      modalVisible: false,
      select: {},
      quantity: "1",
      adminId:this.props.route.params.adminId
    };

    this.isSearch = false
    this.isLoadMore = true
    this.page = 1

    this.itemId = ""
  }

  componentDidMount = () => {
    // if (this.props.orderId) {
    //   Actions.scan()
    // } else {
      this.search()
    // }    
    this.scanListener = DeviceEventEmitter.addListener(
      'barcodescan',
      (barcode) => {
        if (this.itemId) {
          axios
            .post(`${config.apiurl}/Merchandise/updateMerchandiseData/${this.itemId}`, {
              barcode: barcode.barcode,
            })
            .then(({ status }) => {
              if (status == 200) {
                let tmpData = this.state.data
                for (let i = 0; i < tmpData.length; i++) {
                  if (tmpData[i]._id == this.itemId) {
                    tmpData[i].barcode = barcode.barcode
                    break
                  }
                }
                this.setState({ data: tmpData })
              }
            })
            .catch((error) => console.log(error))
        } else {
          this.setState({ searchText: barcode.barcode }, () => {
            this.search().then((res) => {
              if (res.length == 1) {
                this.setState({
                  select: res[0],
                  quantity: "1",
                  modalVisible: true
                })
              }
            })
          })
        }
      }
    )
  }
  componentWillUnmount = () => {
    this.scanListener.remove()
  }
  onChangeText = text => {
    if (text != "") {
      if (!this.isSearch) {
        this.isSearch = true
        setTimeout(() => {
          this.page = 1
          this.search()
        }, 1000)
      }
      this.setState({
        searchText: text,
        display: 'search'
      })
    } else {
      this.setState({
        searchItems: [],
        searchText: "",
        display: 'hotsales'
      })
    }
  }
  addToOrder = () => {
    let payload = {
      orderId: this.state.orderId,
      merchandiseItems: {
        orderQuantity: this.state.quantity,
        merchandiseId: this.state.select._id,
      },
    };
    axios
      .post(config.apiurl + "/order/updateOrderItem", payload)
      .then((res) => {
        if (res.data.ok) {         
          Alert.alert("Success")
        } else {
          Alert.alert(res.data.message)         
        }
      })
      .catch((err) => console.log(err));
  }

  search = () => {    
    return new Promise((reslove, reject) => {
      let params = {
        adminid: this.state.adminId,
        page: this.page,
      }
      // if (this.state.selectCategory) {
      //   params['category'] = this.state.selectCategory
      // }
      if (this.state.searchText) {
        params['name'] = this.state.searchText
      }

      fetch(config.apiurl + "/merchandise/getShopMerchandiseV2?" + (new URLSearchParams(params)).toString(), {
        'method': "GET",
        'headers': {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((res) => {
          return res.json()
        })
        .then((res) => {
          if (!res.hasOwnProperty("error")) {
            let tmpData = []
            if (this.page == 1) {
              tmpData = res.data
            } else {
              tmpData = this.state.data.concat(res.data)
            }
            //remove duplicate data
            tmpData = tmpData.filter((item, index) => {
              // 返回第一个符合条件的索引是否为当前索引
              return tmpData.findIndex((obj) => {
                return JSON.stringify(obj) === JSON.stringify(item);
              }) === index;
            });

            this.setState({
              data: tmpData,
              display: 'search'
            }, () => {
              reslove(tmpData)  
              //Check if the data has duplicate data
              let duplicate=[]
              for (let i = 0; i < this.state.data.length; i++) {
                for (let j = i + 1; j < this.state.data.length; j++) {
                  if (JSON.stringify(this.state.data[i]) === JSON.stringify(this.state.data[j])) {
                    duplicate.push(this.state.data[i])
                  }
                }
              }
              console.log('duplicate',duplicate.length);         
            })
            this.isSearch = false
            if (res.data.length < 10) {
              this.isLoadMore = false
            }
          }
          this.setState({ isLoading: false })
        })
        .catch((err) => {
          this.isSearch = false
          this.setState({ isLoading: false })
          console.log(err)
        })
    })
  }
  loadMore = () => {
    if (this.isLoadMore) {
      this.page++
      this.search()
    }
  }
  renderEmptyComponent = () => {
    return (
      <View>
        <Text style={{
          fontFamily: FONT_FAMILY,
          alignSelf: 'center',
          marginTop: 100,
          fontSize: 24,
          color: 'rgb(74,74,74)'
        }}
        >
          No results
				</Text>
      </View>
    )
  }
  renderItem = ({ item }) => {
    return (
      <View
        style={{
          backgroundColor: "white",
          justifyContent: "center",
          height: 200,
          paddingTop: 20,
          width: Common.SCREEN_WIDTH
        }}
      >
        <TouchableOpacity onPress={() => {
          //if (this.props.orderId) {
          this.setState({
            select: item, quantity: "1",
            modalVisible: !this.state.modalVisible
          })
          // }
        }}>

          <View style={{ flexDirection: "row", justifyContent:'center' }}>
            {/* <Image
              style={{
                marginLeft: 10,
                width: 100,
                height: 100,
              }}
              source={{
                uri: config.apiurl + "/image/default.jpg",
              }}
            /> */}

            <View
              style={{
                marginHorizontal: 10,
                paddingHorizontal: 10,
                flexDirection: "column",
              }}
            >
              <Text style={{ width: 250 * Common.WIDTH_DIVISOR, fontWeight: "bold" }}>{item.name}</Text>
              <Text style={{ marginTop: 8 * Common.WIDTH_DIVISOR }}>${item.price}</Text>
              <Text style={{ marginTop: 8 * Common.WIDTH_DIVISOR }}>Quantity: {item.stock}</Text>
              {item.barcode ? <Text style={{ marginTop: 8 * Common.WIDTH_DIVISOR }}>Barcode: {item.barcode}</Text> : null}

              {/* {this.state.orderId ? null : <TouchableOpacity onPress={() => {
                this.itemId = item._id
                Actions.scan()
              }}>
                <View style={{
                  backgroundColor: COLORS.MAIN,
                  borderRadius: 10,
                  height: 30,
                  width: 200,
                  marginTop: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 5,
                }}>
                  <Text style={{
                    fontFamily: FONT_FAMILY,
                    color: "white",
                    fontSize: 15,
                    marginHorizontal: 10,
                  }}>{item.barcode ? "Edit Barcode" : "Add Barcode"}</Text>
                </View>
              </TouchableOpacity>} */}
            </View>

          </View>
        </TouchableOpacity>
      </View >
    )
  }
  render() {
    return (
      <SafeAreaView style={{}}>
        {this.state.orderId ? <Header /> : null}
        {this.state.orderId ? <Text style={STYLES.TAB_TITLE}>Add Product</Text> : null}
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 22 * Common.WIDTH_DIVISOR,
          backgroundColor: "#ffffff",
          borderStyle: "solid",
          borderWidth: 1 * Common.WIDTH_DIVISOR,
          height: 40 * Common.WIDTH_DIVISOR,
          borderColor: "#cad1d7",
          marginLeft: 8 * Common.WIDTH_DIVISOR,
          marginTop: 10 * Common.WIDTH_DIVISOR,
        }}>
          <Image style={{
            width: 18 * Common.WIDTH_DIVISOR,
            height: 18 * Common.WIDTH_DIVISOR,
            marginLeft: 18 * Common.WIDTH_DIVISOR,
          }} source={require('../../resource/HomeTab/search-icon.png')} />
          <TextInput
            style={{
              width: 250 * Common.WIDTH_DIVISOR,
              fontFamily: FONT_FAMILY,
              fontSize: 12 * Common.WIDTH_DIVISOR,
              marginLeft: 11 * Common.WIDTH_DIVISOR,
            }}
            returnKeyType='search'
            onSubmitEditing={this.search}
            placeholder='Please input product name'
            placeholderTextColor='#c5c5c5'
            onChangeText={text => this.onChangeText(text)}
            value={this.state.searchText}
          />
          {/* <TouchableOpacity onPress={() => {
            this.itemId = ""
            this.props.navigation.navigate('scan')
          }}>
            <Text style={{ marginRight: 20, fontSize: 20 * Common.WIDTH_DIVISOR, alignSelf: "center", fontWeight: "bold" }}>
              Scan
            </Text>
          </TouchableOpacity> */}
        </View>

        <FlatList
          style={{ backgroundColor: COLORS.GRAY_1 }}
          data={this.state.data}
          renderItem={this.renderItem}
          keyExtractor={(item) => item._id}
          showsVerticalScrollIndicator={false}
          ListEmptyComponent={this.renderEmptyComponent}
          //下拉刷新
          refreshing={this.state.isLoading}
          onRefresh={() => {
            this.page = 1
            this.isLoadMore = true
            this.setState({ isLoading: true })
            this.search()
          }}
          onEndReached={this.loadMore}
          onEndReachedThreshold={0.1}
        />

        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            this.setState({ modalVisible: !this.state.modalVisible });
          }}
        >
          <TouchableWithoutFeedback onPress={() => {
            this.setState({ modalVisible: !this.state.modalVisible });
          }}>
            <View style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              marginTop: 22,
              backgroundColor: "rgba(220,220,220,0.5)"
            }}>
              <View style={{
                margin: 20,
                backgroundColor: "white",
                borderRadius: 20,
                padding: 35,
                alignItems: "center",
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2
                },
                shadowOpacity: 0.25,
                shadowRadius: 4,
                elevation: 5
              }}>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <View>
                      <Text style={{ fontWeight: "bold" }}>{this.state.select.name}</Text>
                      <Text style={{ marginTop: 5 }}>Price: ${this.state.select.price}</Text>
                      <Text style={{ marginTop: 5 }}>Stock: {this.state.select.stock}</Text>
                      {this.state.orderId ? null : <Text style={{ marginTop: 5 }}>{this.state.select.note}</Text>}
                    </View>
                  </View>

                  {this.state.orderId ?
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        marginBottom: 5,
                      }}
                    >
                      <TextInput
                        style={{
                          height: 35,
                          width: 45,
                          borderColor: "gray",
                          borderWidth: 1,
                          marginLeft: 5,
                        }}
                        onChangeText={(text) => {
                          this.setState({ quantity: text })
                        }}
                        value={this.state.quantity}
                        keyboardType="number-pad"
                      />
                    </View> : null}
                  {this.state.orderId ? <TouchableOpacity onPress={this.addToOrder}>
                    <View style={{
                      backgroundColor: COLORS.MAIN,
                      borderRadius: 10,
                      padding: 3,
                      marginLeft: 5,
                      marginTop: 25,
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <Text style={
                        {
                          fontFamily: FONT_FAMILY,
                          color: "white",
                          fontSize: 25,
                          marginVertical: 4,

                          marginHorizontal: 10,
                        }}>Add to order</Text>
                    </View>
                  </TouchableOpacity> : null}

                </View>

              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </SafeAreaView >
    )
  }
}
const styles = StyleSheet.create({
  PageButton: {
    marginTop: 15,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 12,
    backgroundColor: "pink",
    borderRadius: 15,
    paddingVertical: 12,
  },
  avatar: {
    width: 70,
    height: 70,
    marginLeft: 20,
    marginRight: 16,
    borderRadius: 70,
    backgroundColor: COLORS.MAIN,
    marginTop: 36,
  },

  name: {
    fontFamily: FONT_FAMILY,
    fontSize: 24,
    lineHeight: 29,
    color: COLORS.GRAY_4,
    marginTop: 48,
    marginRight: 20,
  },

  email: {
    fontSize: 16,
    lineHeight: 19,
    marginRight: 20,
  },

  row: {
    paddingVertical: 24,
    paddingLeft: 10,
    paddingRight: 22,
    flexDirection: "row",
    marginHorizontal: 20,
    alignContent: "center",
  },

  rowText: {
    flex: 1,
    fontSize: 16,
    lineHeight: 19,
  },

  icon: {
    marginRight: 12,
  },

  content: {
    flex: 1,
  },

  container: {
    flex: 1,
    // height: Common.SCREEN_WIDTH
  },
  containerPicker: {
    marginTop: 20,
    height: 1000,
    marginHorizontal: 20,
  },

  pickerItemBG: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 18,
    paddingBottom: 16,
  },
  pickerSelectedItemBG: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.TRANSPARENT_GRAY_2,
    paddingTop: 18,
    paddingBottom: 16,
    borderRadius: 34,
  },
  pickerItemText: {
    fontFamily: FONT_FAMILY,
    fontSize: 20,
  },
  servicePicker: {
    marginTop: 20,
    marginHorizontal: 20,
  },
  subContainer: {
    marginHorizontal: 20,
  },
  subNameText: {
    marginRight: 5,
  },
});
