/**
 * Created by zhujianing on 2019-02-26.
 */

import {
    Dimensions,
    PixelRatio,
    Platform
} from 'react-native';
export let screenWidth = Dimensions.get('window').width;
export let screenHeight = Dimensions.get('window').height;
export let fontScale = PixelRatio.getFontScale();
export let pixelRatio = PixelRatio.get();
//像素密度
export const DEFAULT_DENSITY = 2;
//px转换成dp
//以iphone6为基准,如果以其他尺寸为基准的话,请修改下面的750和1334为对应尺寸即可.
const width2x = 750 / DEFAULT_DENSITY;
//px转换成dp
const height2x = 1334 / DEFAULT_DENSITY;
// iPhoneX
const X_WIDTH = 375;
const X_HEIGHT = 812;

/**
 * 设置字体的size（单位px）
 * @param size 传入设计稿上的px
 * @returns {Number} 返回实际sp
 */
export function autoFontSize(size) {
    let scaleWidth = screenWidth / width2x;
    let scaleHeight = screenHeight / height2x;
    let scale = Math.min(scaleWidth, scaleHeight);
    size = Math.round((size * scale + 0.5));
    return size / DEFAULT_DENSITY;
}

/**
 * 屏幕适配,缩放size
 * @param size
 * @returns {Number}
 */
export function autoScaleSize(size) {
    let scaleWidth = screenWidth / width2x;
    let scaleHeight = screenHeight / height2x;
    let scale = Math.min(scaleWidth, scaleHeight);
    size = Math.round((size * scale + 0.5));
    return size / DEFAULT_DENSITY;
}

/**
 * 判断是否为iphoneX
 * @returns {boolean}
 */
export function isIphoneX() {
    return (
        Platform.OS === 'ios' &&
        ((screenHeight === X_HEIGHT && screenWidth === X_WIDTH) ||
        (screenHeight === X_WIDTH && screenWidth === X_HEIGHT))
    )
}

export function getDistance(lat1, lng1, lat2, lng2){
    var radLat1 = lat1 * Math.PI / 180.0;
    var radLat2 = lat2 * Math.PI / 180.0;
    var a = radLat1 - radLat2;
    var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * 6378.137;
    s = Math.round(s * 10000) / 10000;
    return s
}

export default class Common {
    static SCREEN_WIDTH = screenWidth;
    static SCREEN_HEIGHT = screenHeight;
    static PIXEL_RATIO = pixelRatio;
    static DEFAULT_DENSITY = DEFAULT_DENSITY;
    static WIDTH_DIVISOR = screenWidth / 375;
    static HEIGHT_DIVISOR = screenHeight / 667;
    static Full_Display_Height = (Math.floor(screenWidth / screenHeight * 100) == 46) ? 15 : 0
    static HIT_SLOP = { top: 50, left: 50, bottom: 50, right: 50};
    static STATUS_BAR_HEIGHT = Platform.select({
        ios: Common.isIphoneX ? 44 : 20,
        android: 0,
    }) * Common.WIDTH_DIVISOR;

    //是否为iphoneX
    static isIphoneX = isIphoneX();
    static isAndroid = Platform.OS === 'android'

    // deepclone obj with circular references
	static deepClone = (obj, hash = new WeakMap()) => {
        // Do not try to clone primitives or functions
        if (Object(obj) !== obj || obj instanceof Function) {
           return obj
        }
  
        // Cyclic reference
        if (hash.has(obj)) {
           return hash.get(obj)
        }
  
        // Try to run constructor
        try {
           var result = new obj.constructor();
        } catch (e) { // Constructor failed, create object without running the constructor
           result = Object.create(Object.getPrototypeOf(obj))
        }
  
        // support for some standard constructors (extend as desired)
        if (obj instanceof Map) {
           Array.from(obj, ([key, val]) => result.set(this.deepClone(key, hash), this.deepClone(val, hash)))
        } else if (obj instanceof Set) {
           Array.from(obj, (key) => result.add(deepClone(key, hash)))
        }
  
        // Register in hash    
        hash.set(obj, result)
  
        // Clone and assign enumerable own properties recursively
        return Object.assign(
           result,
           ...Object
              .keys(obj)
              .map((key) => ({ [key]: this.deepClone(obj[key], hash) }))
        )
     }

    //global style sheet
    static styles = {
        flex1: {
            flex: 1
        },

        globalFont: {
            fontFamily: "Helvetica",
        },

        defaultContainer: {
            height: Common.SCREEN_HEIGHT,
            width: Common.SCREEN_WIDTH,
            backgroundColor: 'white',
        },

        flexRow:{
            flexDirection: 'row',
        },

        flexCol:{
            flexDirection: 'column',
        },
        
        flexWrap: {
            flexWrap: 'wrap'
        },

        alignItemsCenter:{
            alignItems: 'center',
        },

        justifyCenter: {
            justifyContent: 'center',
        },

        justifySpaceBetween:{
            justifyContent: 'space-between',
        },

        justifyFlexEnd:{
            justifyContent: 'flex-end',
        },
        
        lightGreyText: {
            color: "rgba(128, 128, 128, 1)",
        },

        lightBlueText: {
            color: "rgba(0 ,137, 255, 1)",
        },

        lightGreyBG: {
            color: "rgba(229 ,229, 229, 1)",
        },

        lightBlueBG: {
            color: "rgba(0 ,174, 255, 1)",
        },
        whiteBG: {
            backgroundColor: '#ffffff'
        },
        backButton: {
            marginLeft: 20 * Common.WIDTH_DIVISOR,
            marginTop: 20 * Common.WIDTH_DIVISOR,
        },

        bakcButtonContainer: {
            paddingBottom: 20 * Common.WIDTH_DIVISOR,
            paddingLeft: 20 * Common.WIDTH_DIVISOR,
            paddingRight: 20 * Common.WIDTH_DIVISOR,
            paddingTop: 20 * Common.WIDTH_DIVISOR,
            marignTop: 20 * Common.WIDTH_DIVISOR,
            marginLeft: 20 * Common.WIDTH_DIVISOR,  
        },

        textFieldContainer: {
            alignSelf: 'center',
            fontSize: 14 * Common.WIDTH_DIVISOR,
            height: 50 * Common.WIDTH_DIVISOR,
            width: 300 * Common.WIDTH_DIVISOR,
            marginLeft: 38,
            marginRight: 38,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: 45,
            borderStyle: "solid",
            borderWidth: 0.5,
            borderColor: "#cccccc"
        },
        iconLeft: {
            width:13 * Common.WIDTH_DIVISOR,
            height:15 * Common.WIDTH_DIVISOR,
            marginLeft: 25 * Common.WIDTH_DIVISOR,
            marginRight: 15 * Common.WIDTH_DIVISOR,
        },
        iconRight: {
            width: 15 * Common.WIDTH_DIVISOR, 
            height: 11 * Common.WIDTH_DIVISOR,
            marginRight: 25 * Common.WIDTH_DIVISOR, 
        },
        verifyButton: {
            paddingTop: 10 * Common.WIDTH_DIVISOR,
            paddingBottom: 10 * Common.WIDTH_DIVISOR,
            paddingLeft: 18 * Common.WIDTH_DIVISOR,
            paddingRight: 15 * Common.WIDTH_DIVISOR,
            marginRight: 7.5 * Common.WIDTH_DIVISOR,
            marginLeft: 5 * Common.WIDTH_DIVISOR,
            borderRadius: 22,
            backgroundColor: '#e5e5e5',
        },

        verifyButtonText: {
            color: 'blue',
            fontSize: 14 * Common.WIDTH_DIVISOR,
        },
        screenTopPadding: {
            paddingTop: Common.STATUS_BAR_HEIGHT
        },
    }

    //字体自适应大小
    static autoFontSize(size) {
        return autoFontSize(size);
    }

    //尺寸自适应大小
    static autoScaleSize(size) {
        return autoScaleSize(size);
    }

    //获取指定格式时间字符串
    static getDateFormat(date, format = false) {
        if (false !== format) {
            format = "yyyy-MM-dd HH:mm:ss";
        }
        return date.format(format);
    }
    static getDistance(lat1, lng1, lat2, lng2)
    {
        return getDistance(lat1, lng1, lat2, lng2)
    }
}