import React, { useContext } from "react";
import { View, Text, StyleSheet, TouchableOpacity, Image } from "react-native";
import Barcode from "react-native-barcode-svg";
import {
  SCREEN_WIDTH,
  TEXT_STYLES,
  STYLES,
  COLORS,
  FONT_FAMILY,
} from "../Util/Styles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import UserContext from "../Context/UserContext";
const AccountSetting = ({navigation}) => {
  const { user, setUser } = useContext(UserContext);

  const logOut = async () => {
    setUser(null);    
    AsyncStorage.removeItem("user");
    navigation.navigate('Main')
    AsyncStorage.removeItem('acc')
  };
  
  const timestamp = Date.now();
  const dateObj = new Date(timestamp);
  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');   
  const time=hours+':'+minutes
  const barcodeValue=user?user._id:''
  console.log('barcodeValue',barcodeValue);
  
  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>My Account</Text>
      <View style={{ marginVertical: 10 }} />
      <View>
        <TouchableOpacity onPress={() => navigation.navigate('editProfile')}>
          <View style={styles.menuContainer}>
            <Image
              style={styles.menuImage}
              source={require("../../JS/../resource/General/PasswordLock.png")}
            />
            <Text style={styles.menuItem}>Edit Profile</Text>
            <Image
              style={styles.menuArrowImage}
              source={require("../../JS/../resource/Profile/ArrowRight.png")}
            />
          </View>
        </TouchableOpacity>
        {/* <TouchableOpacity onPress={() => navigation.navigate('schedule')}>
          <View style={styles.menuContainer}>
            <Image
              style={styles.menuImage}
              source={require("../../JS/../resource/General/PasswordLock.png")}
            />
            <Text style={styles.menuItem}>Work Schedule</Text>
            <Image
              style={styles.menuArrowImage}
              source={require("../../JS/../resource/Profile/ArrowRight.png")}
            />
          </View>
        </TouchableOpacity> */}
        <TouchableOpacity onPress={() => logOut()}>
          <View style={styles.menuContainer}>
            <Image
              style={styles.menuImage}
              source={require("../../JS/../resource/HomeTab/SignInIcon.png")}
            />
            <Text style={styles.menuItem}>Log out</Text>
          </View>
        </TouchableOpacity>
        {user?
            <View style={{flexDirection:'column', justifyContent:'center',alignItems:'center'}} >                  
            <Barcode maxWidth={0.8*SCREEN_WIDTH} value={barcodeValue} format="CODE128"/>
            <Text style={styles.menuItem}>{user.name+' '+time}</Text>
          </View>:null
        }  
        
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: SCREEN_WIDTH,
  },
  menuContainer: {
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
  },
  menuImage: {
    width: 25,
    height: 25,
    marginLeft: 25,
    paddingRight: 5,
    marginBottom: -5,
  },
  menuArrowImage: {
    width: 15,
    height: 25,
    marginLeft: 25,
    paddingRight: 5,
    marginBottom: -5,
    position: "absolute",
    right: 25,
  },
  titleText: {
    ...TEXT_STYLES.FONT_TYPE_6,
    ...{
      marginLeft: 24,
      textAlign: "left",
      marginRight: 6,
      marginTop: 24,
      marginBottom: 12,
    },
  },

  radioButtonRow: {
    marginHorizontal: 22,
    marginTop: 42,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  createNewAccountText: {
    color: COLORS.MAIN,
    fontSize: 16,
    textDecorationLine: "underline",
    textAlign: "center",
    marginTop: 40,
  },

  divider: {
    marginVertical: 10,
  },
  menuItem: {
    fontSize: 25,
    margin: 15,
    paddingLeft: 5,
    paddingTop: 5,
  },
});

export default AccountSetting;
