import React, { useState, useEffect } from 'react';
import {
    Platform,
    View,
    ActivityIndicator,
} from 'react-native';

import PropTypes from 'prop-types';
import Common from './common';

const Loading = ({ hide }) => {
    const [modalVisible, setModalVisible] = useState(!hide);

    useEffect(() => {
        setModalVisible(!hide);
    }, [hide]);

    const close = () => {
        if (Platform.OS === 'android') {
            setTimeout(() => {
                setModalVisible(false);
            }, 1000)
        } else {
            setModalVisible(false);
        }
    }

    const show = () => {
        setModalVisible(true);
    }

    if (!modalVisible) {
        return null
    }

    return (
        <View
            style={{
                position: "absolute",
                width: Common.SCREEN_WIDTH,
                height: Common.SCREEN_HEIGHT,
                backgroundColor: "rgba(0,0,0,0)"
            }}
        >
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.5)', width: 100, height: 100, alignItems: 'center' }}>
                    <ActivityIndicator
                        animating={true}
                        color='white'
                        style={{
                            marginTop: 20,
                            width: 60,
                            height: 60,
                        }}
                        size="large" />
                </View>
            </View>
        </View>
    );
}

Loading.propTypes = {
    hide: PropTypes.bool.isRequired,
};

export default Loading;
