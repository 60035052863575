import React, { useState, useContext, useEffect } from "react";
import { View, Text, StyleSheet, TextInput, Alert } from "react-native";
import {
  SCREEN_WIDTH,
  TEXT_STYLES,
  COLORS
} from "../Util/Styles";
import { RedRoundedButton } from "../SharedComponents/StyledButtons";
import UserContext from "../Context/UserContext";
import config from "../Util/config";
import axios from "axios";

const EditProfile = ({navigation}) => {
  const { user, setUser } = useContext(UserContext);
  const [name, setName] = useState(user.name ? user.name : "");
  const [phone, setPhone] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");

  useEffect(() => {
    setName(user.name)
    setPhone(user.phone)
  }, []);
  const save = async () => {
    if (!name) {
      Alert.alert("Please input name");
      return;
    }
    const payload = {
      email: user.email,
      name: name,
      phone: phone
    };

    try {
      axios
        .post(config.apiurl + "/employee/updateEmployeeProfile", payload)
        .then((res) => {
          if (res.data.error) {
            Alert.alert(res.data.error);
            return;
          }

          setUser(res.data)
          navigation.goBack();
          Alert.alert("Profile updated");
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>My Account</Text>
      <View style={{ marginVertical: 10 }} />
      <View style={styles.divider} />
      <TextInput
        placeholder={"Name"}
        placeholderTextColor="rgba(0,0,0,0.5)" 
        style={styles.secureInput}
        value={name}
        onChangeText={(text) => setName(text)}
      />
      <TextInput
        placeholder={"Phone"}
        placeholderTextColor="rgba(0,0,0,0.5)" 
        style={styles.secureInput}
        value={phone}
        onChangeText={(text) => setPhone(text)}
      />
      <RedRoundedButton text={"Save"} onPress={() => save()} />
    </View>
  );
};

const styles = StyleSheet.create({
  secureInput: {
    backgroundColor: "#EFEFEF",
    paddingVertical: 20,
    paddingHorizontal: 24,
    margin: 5,
    borderRadius: 48,
    marginHorizontal: 20,
  },
  container: {
    width: SCREEN_WIDTH,
  },

  titleText: {
    ...TEXT_STYLES.FONT_TYPE_6,
    ...{
      marginLeft: 24,
      textAlign: "left",
      marginRight: 6,
      marginTop: 24,
      marginBottom: 12,
    },
  },

  radioButtonRow: {
    marginHorizontal: 22,
    marginTop: 42,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  createNewAccountText: {
    color: COLORS.MAIN,
    fontSize: 16,
    textDecorationLine: "underline",
    textAlign: "center",
    marginTop: 40,
  },

  divider: {
    marginVertical: 10,
  },
});

export default EditProfile;
