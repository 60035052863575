import config from "./Util/config";

import axios from "axios";

const API = {
  login: (payload, onSuccess, onFailed) => {
    if (payload === null) {
      console.log("error");
      onFailed();
      return;
    }
    axios
      .post(config.apiurl + "/employee/login", JSON.parse(payload))
      .then((res) => {
        if (res.data.error) {
          onFailed(res.data.error);
          console.log(res.data.error);
          return;
        }
        onSuccess(res.data);
      })
      .catch((err) => console.log(err));
  },
  getAllServicesByAdminID: (id, onSuccess, onFailed) => {
    axios
      .get(config.apiurl + "/user/getAllServicesByAdminID?id=" + id)
      .then((res) => {
        if (res.hasOwnProperty("error")) {
          onFailed();
        } else {
          onSuccess(res);
        }
      })
      .catch((err) => {
        console.log(err);
        onFailed();
      });
  },
  getEmployeeByService:(serviceid,adminid,onSuccess,onFailed)=>{
    const opt = { 
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }
    axios.get(
      `${config.apiurl}/user/getEmployeeByService?serviceid=${serviceid}&adminid=${adminid}`, opt)
      .then((res) =>{
      if (res.status === 200) {
          if (res.data.length > 0) {
            onSuccess(res)                                 
          } else {
              onFailed(res)             
          }
        }})   
      .catch((err) => {
        console.log(err)     
      })
  },
  getSingleOrder:(orderId,onSuccess,onFailed)=>{
    axios
        .get(config.apiurl + "/order/getSingleOrder/" + orderId)
        .then((res) => {
          if (res.data.ok) {
            onSuccess(res)           
          }else{
            onFailed(res)
          }
        })
        .catch((err) => console.error(err));
  }
};

export { API };
