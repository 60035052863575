import React from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Alert,
  TouchableOpacity
} from "react-native";
import Header from "../SharedComponents/Header";
import {  FONT_FAMILY,  STYLES,  TEXT_STYLES } from "../Util/Styles";
import timeTransfer from "../Util/timeTransfer";
import config from "../Util/config";
import axios from "axios";
import moment from "moment";
import { API } from "../API";

export default class OrderDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      merchandiseTotal: "",
      serviceTotal: "",
      orderMerchandiseItems: [],
      refreshing:false
    };
  }
  getOrderData = () => {
    if (this.props.route.params.orderId) {
      API.getSingleOrder(this.props.route.params.orderId,
        (res)=>{
          console.log('orderData',res.data);
          const merchandiseObjects = Object.values(
            res.data.data.orderMerchandiseItems
          );
          for (let i = 0; i < merchandiseObjects.length; i++) {
            merchandiseObjects[i]['type'] = "product"
          }
          let merTotal = merchandiseObjects.reduce(
            (acc, cur) => (acc += parseFloat(cur.merchandiseItemTotal)),
            0
          );
          const eventObjects = Object.values(res.data.data.orderEventItems);
          for (let i = 0; i < eventObjects.length; i++) {
            eventObjects[i]['type'] = "service"
          }
          this.setState({
            data: merchandiseObjects.concat(eventObjects),
            orderMerchandiseItems: merchandiseObjects,
            merchandiseTotal: parseFloat(merTotal).toFixed(2),
            serviceTotal: eventObjects.reduce(
              (acc, cur) => (acc += parseFloat(cur.selectedService.price)),
              0
            ),
            refreshing:false
          })
        },
        (res)=>{
          console.log(res);
        }
      )     
    }
  }
  componentDidMount = () => {
    this.getOrderData()
  };

  onRefresh=()=>{
    this.setState({refreshing:true})
    this.getOrderData()
  }
  renderItem = ({ item, index }) => {
    if (item.type == 'product') {
      return (
        <View key={item._id + index} style={{ flexDirection: "column" }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",            
              marginBottom:10,
              paddingLeft:25,
              paddingRight:25
            }}
          >
            {/* <View
              style={{
                flexDirection: "row",
              }}
            >
              <Image
                style={{
                  width: 100,
                  marginTop: 5,
                  height: 100,
                }}
                source={{
                  uri: item.imgURL[0]
                    ? item.imgURL[0]
                    : "https://cdn.shopify.com/s/files/1/0367/1749/4408/files/custom_resized_fa4ecd6f-9f51-40b8-9990-f9f7d19eec2e_720x.jpg",
                }}
              />
            </View> */}

            <View
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignSelf: "center",

              }}
            >
              <Text >{item.name}</Text>
              <Text > ${item.price}</Text>
              <Text >{"Quantity: " + item.orderQuantity}</Text>
              {/* <Text>Note: {item.note}</Text> */}
              <Text>Item Price ${item.merchandiseItemTotal}</Text>
            </View>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              {/* <TouchableOpacity onPress={() => {
                Alert.alert(
                  "Alert",
                  "confirm delete",
                  [
                    {
                      text: 'Cancel',
                      onPress: () => {

                      },
                      style: 'cancel',
                    },
                    {
                      text: 'OK', onPress: () => {
                        axios
                          .put(config.apiurl + "/order/deleteOrderItems", {
                            orderId: this.props.route.params.orderId,
                            merchandiseItems: [item._id],
                          })
                          .then((res) => {
                            this.getOrderData()
                            console.log(res.data.message);
                          })
                          .catch((err) => console.error(err));
                      }
                    }
                  ],
                  { cancelable: false },
                )
              }}>
                <Text style={{ color: "red", fontSize: 20, }}>delete</Text>
              </TouchableOpacity> */}
            </View>
          </View>        
        </View >
      )
    }
    else {
      return (<View key={item._id + index} style={{ flexDirection: "column"}}>
         <View style={STYLES.TAB_TITLE_DIVIDER }/>
        <View
          style={{
            flexDirection: "row",
            justifyContent: 'space-between',
            
          }}
        >
         
          <View style={{
            flexDirection: "column", flex: 2,
          }}>
            <Text style={{
              marginLeft: 30,
              fontWeight: "bold"
            }}>Service Name: </Text>
            <Text style={{
              marginLeft: 30,
              fontWeight: "bold"
            }}>{item.selectedService.name}</Text>
          </View>


          <View
            style={{
              flex: 3,
              flexDirection: "column",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <Text >Date: {moment(item.day).format("YYYY-MM-DD")} </Text>
            <Text >Time: {timeTransfer(moment(item.start).format("HH:mm"))} - {timeTransfer(moment(item.end).format("HH:mm"))}</Text>            
            <Text >Price: ${item.selectedService.price}</Text>
            {item.message ? <Text >Note: {item.message}</Text> : null}
            {item.selectedEmployee && item.selectedEmployee.name ? <Text>Provide by {item.selectedEmployee.name}</Text> : null}
          </View>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
          </View>
        </View>      
      </View >)
    }
  }
  renderEmptyComponent = () => {
    return (
      <View>
        <Text style={styles.emptyComponent}>
          No results
        </Text>
      </View>
    )
  }

  render() {
    return (
      <View style={styles.container}>
        <Header/>
        <View style={{
          alignItems: "center"
        }}>

          <Text style={styles.titleText}>Order #: {this.props.route.params.orderId}</Text>
          <Text style={styles.titleText}>Name: {this.props.route.params.item.customerName}</Text>
          <Text style={styles.subTitleText2}>
            Merchandise total: ${this.state.merchandiseTotal}
          </Text>
          <Text style={styles.subTitleText2}>
            Service total: ${this.state.serviceTotal}
          </Text>
        </View>
        <FlatList
          style={{
            marginTop: 20,
          }}
          data={this.state.data}
          renderItem={this.renderItem}
          keyExtractor={(item) => item._id}
          showsVerticalScrollIndicator={false}
          ListEmptyComponent={this.renderEmptyComponent}
          refreshing={this.state.refreshing}
          onRefresh={this.onRefresh}
        />

      </View>
    );
  }
}
const styles = StyleSheet.create({
  titleText: {
    ...TEXT_STYLES.FONT_TYPE_5,
    ...{
      textAlign: "left",
      fontSize: 20,
    },
  },
  subTitleText2: {
    ...TEXT_STYLES.FONT_TYPE_5,
    ...{
      textAlign: "left",
      fontSize: 16,
    },
  },
  container: {
    flex: 1
  },
  emptyComponent:{
    fontFamily: FONT_FAMILY,
    alignSelf: 'center',
    marginTop: 100,
    fontSize: 24,
    color: 'rgb(74,74,74)'
  }
});