import React, { useContext, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  SafeAreaView,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { SCREEN_WIDTH, TEXT_STYLES, STYLES, COLORS } from "./Util/Styles";
import UserContext from "./Context/UserContext";
import AppointmentList from "./StandAlonePages/AppointmentList";

export default function Home({ navigation }) {
  const { user, setUser } = useContext(UserContext);
     
  useEffect(() => {
    async function getUserData() {
      try {
        const userData = await AsyncStorage.getItem('user');
        setUser(userData ? JSON.parse(userData).data : null);
      } catch (error) {
        console.log(error);
      }
    }    
    getUserData();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {user ? (
          <SafeAreaView style={{ flex: 1 }}>
            <View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: SCREEN_WIDTH - 50,
                  justifyContent: "space-between",
                }}
              >
                <View style={styles.loginButton}>
                  <Text style={styles.loginText}>
                    {user.name.length > 0 ? user.name : "Welcome User"}
                  </Text>
                </View>
                <TouchableOpacity onPress={() => { navigation.navigate('Booking') }}>
                  <View style={styles.booking}>
                    <Text style={{ color: "white" }}>Appt Schedule</Text>
                  </View>
                </TouchableOpacity>
              </View>
              <View style={styles.buttonGroup}>
                <TouchableOpacity onPress={() => navigation.navigate('accountSetting')}>
                  <View style={styles.accountButton}>
                    <Image
                      style={styles.icon}
                      source={require("../resource/HomeTab/AccountIcon.png")}
                    />
                    <Text>My Account</Text>
                  </View>
                </TouchableOpacity>
                <View style={styles.radioButtonRow}></View>
                <TouchableOpacity onPress={() => navigation.navigate('message')}>
                  <View style={styles.messageButton}>
                    <Image
                      style={styles.icon}
                      source={require("../resource/HomeTab/MessageIcon.png")}
                    />
                    <Text>Message</Text>
                  </View>
                </TouchableOpacity>
              </View>
              <View style={styles.buttonGroup}>
                <TouchableOpacity onPress={() => navigation.navigate('myOrders')}>

                  <View style={{
                    padding: 6,
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: 4
                  }}>

                    <Text style={{
                      color: "#007BC3",
                      textAlign: "center",
                      textDecorationLine: 'underline'
                    }}>History Appointments</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </SafeAreaView>
        ) : (
          <SafeAreaView style={{ flex: 1 }}>
            <TouchableOpacity onPress={() => { navigation.navigate('Login') }}>
              <View style={styles.loginButton}>
                <Text style={styles.loginText}>Sign In</Text>
                <Image source={require("../resource/HomeTab/SignInIcon.png")} />
              </View>
            </TouchableOpacity>
          </SafeAreaView>
        )}
      </View>
      {user ? <AppointmentList navigation={navigation} /> : <View></View>}
    </View>
  )
}


const styles = StyleSheet.create({
  container: {
    width: SCREEN_WIDTH,
    backgroundColor: "#F5F5F5"
  },

  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    paddingTop: 42,
    paddingBottom: 12,
    borderBottomColor: "#F6D9C4",
    borderBottomWidth: 2
  },

  loginButton: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 24,
    //expo的时候需要加这个属性
    marginTop: 30
  },

  loginText: {
    ...TEXT_STYLES.FONT_TYPE_6,
  },

  accountButton: {
    padding: 6,
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 2,
  },

  messageButton: {
    padding: 6,
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 4,
  },

  icon: {
    marginVertical: 4,
    marginHorizontal: 8,
  },

  buttonGroup: {
    flexDirection: "row",
    paddingLeft: 12,
    alignItems: "center",
  },

  booking: {
    width: 120,
    height: 40,
    borderRadius: 10,
    backgroundColor: COLORS.MAIN,
    alignItems: "center",
    justifyContent: "center"
  },

  loadingIndicatorContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
