import React from "react";
import { StyleSheet, TouchableOpacity, View, Text } from "react-native";
import { FONT_FAMILY, COLORS } from "../Util/Styles";

const RedRoundedButton = (props) => {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <View style={styles.buttonBG}>
        <Text style={styles.buttonText}>{props.text}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonText: {
    fontFamily: FONT_FAMILY,
    color: "white",
    fontSize: 17,
  },

  buttonBG: {
    backgroundColor: COLORS.MAIN,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
    paddingHorizontal: 5,
    marginHorizontal: 22,
    marginTop: 16,
    borderRadius: 36,
  },
});

export { RedRoundedButton };
