import React, { useEffect } from 'react'
import {
    View,
    Text,
    SafeAreaView,
    StyleSheet,
    Image,
    TouchableOpacity,
    BackHandler
} from 'react-native'
import { useNavigation } from '@react-navigation/native';
import Common from '../Util/common';

const Header = (props) => {
    const navigation = useNavigation();

    useEffect(() => {
        const backButtonListener = BackHandler.addEventListener("hardwareBackPress", backButtonOnPress)
        return () => {
            backButtonListener.remove()
        }
    }, [])

    const backButtonOnPress = () => {
        if (!props.handleBackButtonPress instanceof Function ||
            props.handleBackButtonPress === null ||
            props.handleBackButtonPress === undefined
        ) {
            navigation.goBack();
        } else if (props.handleBackButtonPress() === true) {
            navigation.goBack();
        }
    }

    return (
        <View style={styles.container}>
            <SafeAreaView style={styles.headerContent}>
                <TouchableOpacity onPress={backButtonOnPress}>
                    <View style={styles.backButton}>
                        <Image style={{ width: 15 * Common.WIDTH_DIVISOR, height: 15 * Common.WIDTH_DIVISOR }} source={require("../../resource/Components/BackArrow.png")} />
                    </View>
                </TouchableOpacity>
            </SafeAreaView>
        </View>
    )
}

export default Header


const styles = StyleSheet.create({
    container: {},
    headerContent: {
        flexDirection: "row"
    },
    backButton: {
        paddingVertical: 8 * Common.WIDTH_DIVISOR,
        paddingHorizontal: 12 * Common.WIDTH_DIVISOR,
        marginLeft: 10 * Common.WIDTH_DIVISOR,
        marginTop: 12 * Common.WIDTH_DIVISOR,
        marginBottom: 20 * Common.WIDTH_DIVISOR,
    }
})