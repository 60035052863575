import React, { useState, useContext, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity
} from "react-native";
import { Agenda } from "react-native-calendars";
import { Card } from "react-native-paper";
import axios from "axios";
import moment from "moment";
import UserContext from "../Context/UserContext";
import config from "../Util/config";
import timeTransfer from "../Util/timeTransfer";

const BookingCalendar = ({ navigation }) => {
  const { user } = useContext(UserContext);
  const parseToAgenda = (data) => {
    const parsedData = {};
    for (let i = 0; i < data.length; i++) {
      let eventDate = moment(data[i].day).format("YYYY-MM-DD");
      const eventItem = {
        status: data[i].status,
        eventId: data[i]._id,
        employee: data[i].selectedEmployee ? data[i].selectedEmployee.name : "",
        selectedService: data[i].selectedService ? data[i].selectedService.name : null,
        room: (data[i].selectedService && data[i].selectedService.RoomDoc && data[i].selectedService.RoomDoc.length > 0)
          ? data[i].selectedService.RoomDoc[0].name
          : "",
        duration: data[i].duration,
        startTime: moment(data[i].start).format("HH:mm"),
        endTime: moment(data[i].end).format("HH:mm"),
        day: moment(data[i].day).format("dddd, MMMM, DD, YYYY"),
        customerName: data[i].username,
        shopName: data[i].shopId.shopName,
        note: data[i].message,
        type: data[i].type ? data[i].type : '',
        userId: data[i].userId ? data[i].userId : ""
      };
      if (parsedData[eventDate]) {
        parsedData[eventDate].push(eventItem);
      } else {
        parsedData[eventDate] = [];
        parsedData[eventDate].push(eventItem);
      }
    }

    return { parsedData };
  };
  const fetchEventData = () => {
    const payload = {
      employeeId: user._id,
      startDate: moment().subtract(1, "y").format("YYYY-MM-DD"),
      endDate: moment().add(1, "y").format("YYYY-MM-DD"),
    };
    try {
      axios
        .post(config.apiurl + "/employee/getRangeEmployeeEvents", payload)
        .then((res) => {
          if (res.data.ok) {
            setItems(parseToAgenda(res.data.data).parsedData);
          }
          return [];
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchEventData();
  }, []);

  const [items, setItems] = useState();
  const renderItem = (item) => {
    console.log(item)
    return (
      <TouchableOpacity
        onPress={() => navigation.navigate('appointmentDetail', { item: item })}
        style={{ marginRight: 10, marginTop: 10 }}
      >
        <Card>
          <Card.Content>
            <View>
              <View style={{ flexDirection: "row" }}>
                {item.room ? <Text>{item.room} - </Text> : null}
                <Text>
                  {timeTransfer(item.startTime)}-{timeTransfer(item.endTime)}
                </Text>
              </View>
              <View>
                <Text>{item.selectedService ? item.selectedService : ''}</Text>
                {item.customerName ? <Text>Customer:{item.customerName} </Text> : null}
                {item.type ? <Text>Type:{item.type} </Text> : null}
                {item.note ? <Text>Note:{item.note} </Text> : null}
                <Text>{item.shopName}</Text>
              </View>
            </View>
          </Card.Content>
        </Card>
      </TouchableOpacity>
    );
  };
  return (
    <View style={{ flex: 1 }}>
      <Agenda
        items={items}
        // loadItemsForMonth={loadItems}
        selected={moment().format("YYYY-MM-DD")}
        minDate={moment().subtract(1, "y").format("YYYY-MM-DD")}
        maxDate={moment().add(1, "y").format("YYYY-MM-DD")}
        renderItem={renderItem}
        renderEmptyData={() => (
          <Text
            style={{ fontSize: 30, alignSelf: "center", flex: 1, margin: 30 }}
          >
            No Records Found
          </Text>
        )}
      />
    </View>
  );
};

export default BookingCalendar;
