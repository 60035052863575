import React, { useContext } from "react"
import {
	View,
	Text,
	SafeAreaView,
	StyleSheet,
	DeviceEventEmitter,
	ScrollView,
	Alert,
	Image
} from "react-native"
import { SCREEN_WIDTH, SCREEN_HEIGHT, FONT_FAMILY } from "./Util/Styles"
import Moment from 'moment'
import Common from './Util/common'

// import IdleTimerManager from "react-native-idle-timer"

import FlashMessage, {
	showMessage,
	hideMessage,
} from "react-native-flash-message";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import UserContext from "./Context/UserContext"
const Tab = createBottomTabNavigator()

import Home from "./Home"
import Schedule from "./StandAlonePages/Schedule"

const divisor = Common.WIDTH_DIVISOR > 1.1 ? 1.1 : Common.WIDTH_DIVISOR

export default class Main extends React.Component {
	static contextType = UserContext
	constructor(props) {
		super(props)
	}

	// componentDidMount(){
	// 	// if(this.requestUserPermission()){
	// 	// 	messaging().getToken().then(token=>{
	// 	// 		console.log('token',token);
	// 	// 	})
	// 	// }else{
	// 	// 	console.log('Failed token status',authStatus);
	// 	// }

	// 	// Check whether an initial notification is available
	// 	// messaging().getInitialNotification().then(async (remoteMessage) => {
	// 	// 	if (remoteMessage) {
	// 	// 		console.log(
	// 	// 			'Notification caused app to open from quit state:',
	// 	// 			remoteMessage.notification,
	// 	// 		);
	// 	// 	}
	// 	// });

	// 	// messaging().onNotificationOpenedApp(async (remoteMessage) => {
	// 	// 	console.log(
	// 	// 		'Notification caused app to open from background state:',
	// 	// 		remoteMessage.notification,
	// 	// 	);
	// 	// });

	// 	// messaging().setBackgroundMessageHandler(async (remoteMessage)=>{
	// 	// 	console.log('Message handled in the background',remoteMessage);
	// 	// })

	// 	// messaging().onMessage(async (remoteMessage)=>{
	// 	// 	Alert.alert('A new FCM message arrived',JSON.stringify(remoteMessage))
	// 	// })


	// }

	// componentWillUnmount() {
	// 	IdleTimerManager.setIdleTimerDisabled(false);
	// 	this.loginListener.remove()
	// }

	// async getToken() {
	// 	let fcmToken = await AsyncStorage.getItem('fcm');
	// 	if (!fcmToken) {
	// 		try {
	// 			fcmToken = await messaging().getToken();
	// 		} catch (error) {
	// 			console.log(error)
	// 		}
	// 		if (fcmToken) {
	// 			// user has a device token
	// 			await AsyncStorage.setItem('fcm', fcmToken);
	// 		}
	// 	}
	// 	return fcmToken
	// }

	// async requestUserPermission() {
	// 	const authStatus = await messaging().requestPermission();
	// 	const enabled =
	// 		authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
	// 		authStatus === messaging.AuthorizationStatus.PROVISIONAL;

	// 	if (enabled) {
	// 		console.log('Authorization status:', authStatus);
	// 	}
	// }

	// recieveNotification = (notification) => {	
	// 	if (Actions.currentScene == 'message') {
	// 		DeviceEventEmitter.emit('refreshMessage')
	// 	}

	// 	DeviceEventEmitter.emit('refreshAppointment')

	// 	if (notification.type == "CheckIn") {
	// 		let content = "Customer has arrived! \nTime: " + notification.time + "\nService: " + notification.service + "\nName: " + notification.name
	// 		Alert.alert(content);
	// 	} else if (notification.type == "CancelAppointment") {
	// 		Alert.alert("You have an appointment be cancelled!");	
	// 	} else if (notification.type == "MakeAppointment") {
	// 		console.log(notification)
	// 		let content = "You have an appointment!\nName: " + notification.name + "\nService: " + notification.service + "\nTime: " + Moment(parseInt(notification.start)).format('ll  HH:mm ~ ') + Moment(parseInt(notification.end)).format('HH:mm')
	// 		Alert.alert(content);			
	// 	}
	// }

	// bindToken = () => {
	// 	AsyncStorage.getItem('user').then(value => {
	// 		let user = JSON.parse(value)
	// 		console.log(user)
	// 		messaging().getToken().then(token => {
	// 			axios
	// 				.post(config.apiurl + "/employee/bindFirebaseToken", { id: user.data._id, token: token })
	// 				.then((res) => {
	// 					console.log(res)
	// 				})
	// 				.catch((err) => {
	// 					console.log(err);
	// 				})
	// 		})
	// 	})
	// }


	render() {
		const { user, setUser } = this.context
		return (
			<Tab.Navigator>
				<Tab.Screen
					name='Home'
					component={Home}
					options={{
						headerShown: false,
						tabBarLabel: () => (
							<Text style={{ color: "#EA9B8E", fontWeight: "bold", fontFamily: FONT_FAMILY, fontSize: 16 * divisor }}>Home</Text>
						),
						tabBarIcon: () => (
							<Image
								source={require('../resource/RootTab/TabIconHome.png')}
								style={Common.WIDTH_DIVISOR >= 2 ? {} : { tintColor: '#EA9B8E', width: 25 * divisor, height: 25 * divisor, marginTop: 5 * divisor, }}
							/>
						)
					}}
				/>
				{user ?
					<Tab.Screen
						name='WorkSchedule'
						component={Schedule}
						options={{
							headerShown: false,
							tabBarLabel: () => (
								<Text style={{ color: "#EA9B8E", fontWeight: "bold", fontFamily: FONT_FAMILY, fontSize: 16 * divisor }}>Schedule</Text>
							),
							tabBarIcon: () => (
								<Image
									source={require('../resource/General/schedule.png')}
									style={Common.WIDTH_DIVISOR >= 2 ? {} : { tintColor: '#EA9B8E', width: 25 * divisor, height: 25 * divisor, marginTop: 5 * divisor, }}
								/>
							)
						}}
					/> :
					null
				}

			</Tab.Navigator>
		)
	}
}

