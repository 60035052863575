import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, Image,Keyboard , TouchableWithoutFeedback, ActivityIndicator, ScrollView, TextInput, FlatList } from 'react-native'
import { COLORS, SCREEN_HEIGHT, SCREEN_WIDTH, TEXT_STYLES} from '../Util/Styles'
import Common from '../Util/common'
import config from '../Util/config'

class PickerButton extends React.Component {
    render() {
        if (this.props.invisible) {
            return null
        } else if (this.props.disabled) {
            return (
                <View>
                    <Text>DISABLED</Text>
                </View>
            )
        } else {
            return (
                <View style={this.props.containerStyle}>
                    { this.props.pickerTitle ?
                        this.props.require ?
                            < View style={{ flexDirection: "row" }}>
                                <Text style={styles.pickerTitle}>
                                    {this.props.pickerTitle}
                                </Text>
                                <Text style={{ color: "#EC840A" }}>*</Text>
                            </View>
                            :
                            <Text style={styles.pickerTitle}>
                                {this.props.pickerTitle}
                            </Text>

                        :
                        null
                    }

                    {
                        this.props.isLoading ?
                            <ActivityIndicator />
                            :
                            <TouchableOpacity onPress={this.props.onPress}>
                                <View style={this.props.background === 1 ? styles.pickerButtonWhite : styles.pickerButton}>
                                    <Text style={TEXT_STYLES.FONT_TYPE_3}>
                                        {this.props.isEmpty(this.props.selected) ?
                                            this.props.placeHolder
                                            :
                                            this.props.getDisplayText(this.props.selected)
                                        }
                                    </Text>
                                    <Image source={require('../../resource/General/DropdownArrow.png')} />
                                </View>
                            </TouchableOpacity>
                    }
                </View >
            )
        }
    }
}

class PickerContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: this.props.selected,
            data:[]
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        //该方法内禁止访问this
        if (nextProps.selected !== prevState.selected) {
            return {
                selected: prevState.selected
            }
        }
        return null
    }
    itemOnPress = (item) => this.setState({ selected: item },()=>{console.log('selected',this.state.selected);})

    renderPickerItems = () => {
        let renderedItems = []
        if (this.state.data.length > 0) {
            this.state.data.forEach((item) => {
                renderedItems.push(
                    <TouchableOpacity onPress={() => { this.itemOnPress(item) }} key={this.props.keyExtractor(item)}>
                        {this.state.selected !== null && this.props.isItemTheSame(item, this.state.selected) ?
                            this.props.renderSelectedItem(item)
                            :
                            this.props.renderItem(item)
                        }
                    </TouchableOpacity>
                )
            })
        } else {
            this.props.data.forEach((item) => {
                renderedItems.push(
                    <TouchableOpacity onPress={() => { this.itemOnPress(item) }} key={this.props.keyExtractor(item)}>
                       
                        {this.state.selected !== null&&this.props.isItemTheSame(item, this.state.selected) ?
                            this.props.renderSelectedItem(item)
                            : 
                            this.props.renderItem(item)
                        }
                    </TouchableOpacity>
                )
            })
        }

        return renderedItems
    }

    onCancel = () => {
        this.props.onCancel()
        this.setState({ selected: this.props.selected })
    }

    onConfirm = () => {
        if (this.state.selected === null) {
            this.props.onCancel()
        } else {
            this.props.onConfirm(this.state.selected)
        }
    }

    onChangeText = (text) => {
        this.setState({ searchText: text })
        if (this.props.search == "service") {
            let params = {
                id: this.props.store,
                page: 1,
                limit: 200,
                name: text,
            }
            fetch(config.apiurl + "/service/getAllService?" + new URLSearchParams(params).toString(), {
                'method': "GET",
                'headers': {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((res) => {                    
                    this.setState({ data: res.data })
                })
        }
    }

    render() {
        return this.props.visible ?
            <TouchableWithoutFeedback onPress={this.onCancel}>
                <View style={styles.container}>
                    <TouchableWithoutFeedback>
                        <View style={styles.content}>
                            <Text style={styles.contentTitle}>
                                {this.props.title}
                            </Text>
                            {this.props.search == "service" ? <TextInput
                                style={{
                                    fontSize: 20 * Common.WIDTH_DIVISOR,
                                    marginLeft: 11 * Common.WIDTH_DIVISOR,
                                    flex: 1
                                }}
                                placeholder='Search Service'
                                placeholderTextColor='#c5c5c5'
                                onChangeText={(text) => {
                                    console.log(text)
                                    this.onChangeText(text)
                                }}
                                value={this.state.searchText}
                            /> : null}

                            <View style={this.props.search == "service" || this.props.search == "employee" ? { height: "70%" } : { maxHeight: "70%" }}>
                                <ScrollView style={{ flexGrow: 0 }} onScrollBeginDrag={Keyboard.dismiss} keyboardShouldPersistTaps='always'>
                                    {this.renderPickerItems()}
                                </ScrollView>
                            </View>

                            <View style={styles.confirmButtonContainer}>
                                <TouchableOpacity onPress={this.onConfirm}>
                                    <View style={styles.confirmButton}>
                                        <Text style={TEXT_STYLES.FONT_TYPE_7}>Done</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </TouchableWithoutFeedback>
            :
            null
    }
}

class TimePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: this.props.selected
        }
    }

    itemOnPress = (item) => { this.setState({ selected: item }) }
    
    static getDerivedStateFromProps(nextProps, prevState) {
        //该方法内禁止访问this
        if (nextProps.selected !== prevState.selected) {
            return {
                selected: prevState.selected
            }
        }
        return null
    }
    onCancel = () => {
        this.props.onCancel()
        this.setState({ selected: this.props.selected })
    }

    onConfirm = () => {
        if (this.state.selected === null) {
            this.props.onCancel()
        } else {
            this.props.onConfirm(this.state.selected)
        }
    }

    renderItem = ({ item, index, seperators }) => {
        return (
            <TouchableWithoutFeedback onPress={() => { this.itemOnPress(item) }}>
                {this.state.selected !== null && this.props.isItemTheSame(item, this.state.selected) ?
                    this.props.renderSelectedItem(item)
                    :
                    this.props.renderItem(item)
                }
            </TouchableWithoutFeedback>
        )
    }

    render() {
        return this.props.visible ?
            <TouchableWithoutFeedback onPress={this.onCancel}>
                <View style={styles.container}>
                    <TouchableWithoutFeedback>
                        <View style={styles.content}>
                            <Text style={styles.contentTitle}>
                                {this.props.title}
                            </Text>

                            <View style={this.props.search == "service" || this.props.search == "employee" ? { height: "70%" } : { maxHeight: "70%" }}>
                                <ScrollView style={{ flexGrow: 0 }} onScrollBeginDrag={Keyboard.dismiss} keyboardShouldPersistTaps='always'>
                                    {this.renderPickerItems()}
                                </ScrollView>
                            </View>

                            <View style={styles.confirmButtonContainer}>
                                <TouchableOpacity onPress={this.onConfirm}>
                                    <View style={styles.confirmButton}>
                                        <Text style={TEXT_STYLES.FONT_TYPE_7}>Done</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </TouchableWithoutFeedback>
            :
            null
    }
}

export {
    PickerContent,
    PickerButton,
    TimePicker
}

const styles = StyleSheet.create({
    pickerTitle: {
        ...TEXT_STYLES.FONT_TYPE_3,
        ...{ marginBottom: 8 }
    },

    pickerButton: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: 22,
        paddingBottom: 18,
        paddingHorizontal: 24,
        backgroundColor: 'white',
        borderRadius: 4,
        backgroundColor: COLORS.TRANSPARENT_GRAY_2,
        borderRadius: 6
    },

    pickerButtonWhite: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: 22,
        paddingBottom: 18,
        paddingHorizontal: 24,
        backgroundColor: 'white',
        borderRadius: 4,
        backgroundColor: "white",
        borderRadius: 6
    },

    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: SCREEN_WIDTH,
        height: SCREEN_HEIGHT,
        backgroundColor: COLORS.TRANSPARENT_GRAY_1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },

    content: {
        backgroundColor: "white",
        width: "90%",
        maxHeight: "80%",
        paddingVertical: 20,
        paddingHorizontal: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        borderRadius: 8,
        elevation: 5
    },

    contentTitle: {
        ...TEXT_STYLES.FONT_TYPE_9,
        ...{ textAlign: "center", marginBottom: 22, marginTop: 8 }
    },

    confirmButton: {
        backgroundColor: COLORS.MAIN,
        borderRadius: 30,
        paddingHorizontal: 50,
        paddingTop: 14,
        paddingBottom: 11
    },

    confirmButtonContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 24
    },

    pickerItemContainer: {
        height: "70%"
    }
})