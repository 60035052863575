
import React from "react"
import {
	View,
	Text,
	StyleSheet,
	TouchableOpacity,
	TouchableWithoutFeedback
} from "react-native"
import { STYLES ,COLORS} from "../Util/Styles";

export default class Button extends React.Component {
	render() {
		return (
			<TouchableOpacity onPress={this.props.onPress}>
				<View style={styles.button}>
					<Text>{this.props.text}</Text>
				</View>
			</TouchableOpacity>
		)
	}
}



export function RationButton(props) {	
	
	return (
		<TouchableWithoutFeedback onPress={props.onPress}>
		<View style={[STYLES.FLEX_ROW, props.style, styles.container]}>
			<View style={styles.radioIconOutter}>
				{ props.selected ? <View style={styles.radioIconInner}/> : null }
			</View>
			<Text style={props.textStyle}>{props.text}</Text>
		</View>
	</TouchableWithoutFeedback>
	);
}

const styles = StyleSheet.create({
	button: {
		padding: 24,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(0,0,0,0.3)",
		borderRadius: 24,
		margin: 8
	},
	container: {
        flexDirection: "row",
        alignItems: "center"
    },

    radioIconOutter: {
        marginRight: 6,
        borderWidth: 1,
        borderRadius: 12,
        width: 24,
        height: 24,
        borderColor: "rgba(0,0,0,0.2)",
        justifyContent: "center",
        alignItems: "center"
    },

    radioIconInner: {
        backgroundColor: COLORS.MAIN,
        width: 18,
        height: 18,
        borderRadius: 20
    }
})