import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  DeviceEventEmitter,
  FlatList,
  Modal,
  TextInput,
  TouchableWithoutFeedback,
} from "react-native";
import {
  SCREEN_WIDTH,
  TEXT_STYLES,
  STYLES,
  COLORS,
  FONT_FAMILY,
  SCREEN_HEIGHT,
} from "../Util/Styles";
import moment from "moment";
import axios from "axios";
import config from "../Util/config";
import UserContext from "../Context/UserContext";
import Common from "../Util/common";
const AppointmentList = ({ navigation }) => {
  const { user, setUser } = useContext(UserContext);
  const [refreshing, setRefreshing] = useState(false);
  const [employeeEventData, setEmployeeEventData] = useState([]);

  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    fetchEventData();

    const refreshListener = DeviceEventEmitter.addListener(
      "refreshAppointment",
      () => {
        fetchEventData();
      }
    );

    return () => {
      refreshListener.remove();
    };
  }, []);

  const parseEventData = (data) => {
    let eventData = [];
    for (let i = 0; i < data.length; i++) {
      let eventItem
      if (data[i].type && data[i].type == "blocked") {
        eventItem = {
          status: data[i].status,
          eventId: data[i]._id,
          employee: data[i].selectedEmployee.name,
          selectedService: "Blocked Time",
          duration: data[i].duration,
          startTime: moment(data[i].start).format("hh:mm a"),
          endTime: moment(data[i].end).format("hh:mm a"),
          day: moment(data[i].day).format("dddd, MMMM, DD, YYYY"),
          type: "blocked",
          message: data[i].message ? data[i].message : null,
          adminid: data[i].shopId._id,
          shopName: data[i].shopId.shopName,
          phone: data[i].phone ? data[i].phone : '',
          email: data[i].email ? data[i].email : ''
        };
      } else {
        eventItem = {
          status: data[i].status,
          eventId: data[i]._id,
          userId: data[i].userId ? data[i].userId : "",
          employee: data[i].selectedEmployee && data[i].selectedEmployee.name ? data[i].selectedEmployee.name : null,
          selectedService: data[i].selectedService.name,
          room: (data[i].selectedService.RoomDoc && data[i].selectedService.RoomDoc.length > 0)
            ? data[i].selectedService.RoomDoc[0].name
            : "",
          message: data[i].message,
          orderId: data[i].orderId,
          duration: data[i].duration,
          startTime: moment(data[i].start).format("HH:mm"),
          endTime: moment(data[i].end).format("HH:mm"),
          day: moment(data[i].day).format("dddd, MMMM, DD, YYYY"),
          customerName: data[i].username,
          shopAddress: data[i].shopAddress,
          adminid: data[i].shopId._id,
          shopName: data[i].shopId.shopName,
          phone: data[i].phone ? data[i].phone : '',
          email: data[i].email ? data[i].email : ''
        };
      }
      eventData.push(eventItem);
    }
    return {
      eventData,
    };
  };

  const fetchEventData = useCallback(() => {
    // ... (same as before, but replace `this.setState` with `setRefreshing` and `setEmployeeEventData`)
    let date = new Date()
    // date.setMonth(2, 26)
    let start = date.setHours(0, 0, 0, 0)
    const payload = {
      employeeId: user._id,
      startDate: date.setHours(0, 0, 0, 0),
      endDate: start + 60 * 60 * 24000,
    };
    try {
      axios
        .post(config.apiurl + "/employee/getRangeEmployeeEvents", payload)
        .then((res) => {
          setRefreshing(false)
          if (res.data.ok) {
            setEmployeeEventData(parseEventData(res.data.data).eventData)
          }
          return [];
        })
        .catch((err) => {
          setRefreshing(false)
          console.error(err)
        });
    } catch (error) {
      setRefreshing(false)
      console.error(error);
    }
  }, []);

  const onRefresh = () => {
    setRefreshing(true);
    fetchEventData();
  };

  // ... (same as before, but replace `this
  const handleStart = async (item, index) => {
    const payload = {
      employeeId: user._id,
      eventId: item.eventId,
    };
    try {
      await handleCreateOrder({
        employeeId: user._id,
        user: item.userId,
        eventItems: item.eventId,
        index: index
      }).catch((err) => {
        console.error(err)
      });

      await axios
        .post(config.apiurl + "/employee/startEvent", payload)
        .then((res) => {
          if (res.data.data) {
            setEmployeeEventData(employeeEventData.map((event, i) => {
              if (i === index) {
                return { ...event, status: res.data.data.status };
              }
              return event;
            }));
          }
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateOrder = async (payload) => {
    try {
      let res = await axios.post(config.apiurl + "/order/createOrder", payload)
      if (res.data.ok) {
        let tmp = employeeEventData
        tmp[payload.index].orderId = res.data.data.orderId
        setEmployeeEventData(tmp)
      } else {
        console.log(res.data.message)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleComplete = (item, index) => {
    // ... (same as before)
    const payload = {
      employeeId: user._id,
      eventId: item.eventId,
    };
    try {
      axios
        .post(config.apiurl + "/employee/completeEvent", payload)
        .then((res) => {
          if (res.data.data) {
            DeviceEventEmitter.emit('refreshAppointment')
          }
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddProduct = (item) => {
    navigation.navigate('addProduct',
      {
        orderId: item.orderId,
        adminId: item.adminid,
        navigation: navigation
      })
  };

  const handleAddNote = (item) => {
    setSelectedItem(item);
    setIsNoteModalVisible(true);
  }
  const handleAddedNote = (item) => {
    const postData = {
      userId: item.userId,
      notes: item.note,
      name: user.name
    }
    axios
      .post(`${config.apiurl}/employee/addProgressNote`, postData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            this.setState(res.data[0])
          }
        }
      })
  }

  const handleAddService = (item) => {
    navigation.navigate('addService', {
      orderId: item.orderId,
      adminId: item.adminid,
      item: item,
      eventId: item.eventId,
    })
  };

  const handleOrderDetail = (item) => {
    navigation.navigate('orderDetail', {
      orderId: item.orderId,
      item: item,
    })
  };

  const renderItem = ({ item, index }) => {
    const {
      status,
      customerName,
      day,
      endTime,
      room,
      selectedService,
      shopName,
      startTime,
      message,
      orderId,
      type,
    } = item;
    return (<View style={{
      backgroundColor: "white",
      paddingHorizontal: 10,
      paddingVertical: 10,
      marginBottom: 20,
      marginHorizontal: 10,
      borderRadius: 4,
      shadowColor: "rgba(36, 36, 36, 0.05)",
      shadowOffset: {
        width: 0,
        height: 5
      },
      shadowRadius: 15,
      shadowOpacity: 1
    }}>
      <View
        style={{
          flexDirection: "row",
          alignContent: "space-between",
          justifyContent: "space-between",
        }}
      >
        <TouchableOpacity onPress={() => handleOrderDetail(item)}>
          <View>
            <Text style={{
              fontFamily: FONT_FAMILY,
              color: "#007BC3",
              // fontWeight: "bold",
              textAlign: "left",
              textDecorationLine: 'underline',
              fontSize: 20,
            }}>{orderId}</Text>
          </View>
        </TouchableOpacity>
        {status && status.length > 0 ? (
          <View
            style={{
              padding: 8,
              borderRadius: 2,
              backgroundColor: "#ffffff",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#ea9b8e"
            }}
          >
            <Text style={{
              color: "#ea9b8e"
            }}>{status}</Text>
          </View>
        ) : null}
      </View>
      <View style={{ flexDirection: "column", alignSelf: "flex-start" }}>
        <View style={styles.subContainer}>
          <Text style={styles.subTitleText}>Time</Text>
          <View>
            <Text>{day}</Text>
            <Text>
              {startTime}-{endTime} {room}
            </Text>
          </View>
        </View>
        <View style={styles.subContainer}>
          <Text style={styles.subTitleText}>Service</Text>
          <Text>{selectedService} </Text>
        </View>

        {type && type == "blocked" ? "" : <View style={styles.subContainer}>
          <Text style={styles.subTitleText}>Name</Text>
          <TouchableOpacity onPress={() => {
            navigation.navigate('userDetail', {
              userId: item.userId._id,
              customerName: item.customerName,
              phone: item.phone,
              email: item.email
            })
          }}>
            <Text style={{ textDecorationLine: "underline", color: "#4169E1" }}>{customerName}</Text>
          </TouchableOpacity>
        </View>}

        <View style={styles.subContainer}>
          <Text style={styles.subTitleText}>Shop</Text>
          <Text>{shopName} </Text>
        </View>
        {message ? (
          <View style={styles.subContainer}>
            <Text style={styles.subTitleText}>Note</Text>
            <Text>{message}</Text>
          </View>
        ) : null}
      </View>
      {type && type == "blocked" ? null : <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {/* {(status && status === "started") ||
          status === "completed" || status == "cancelled" ? (
          <View></View>
        ) : (
          <TouchableOpacity onPress={() => handleStart(item, index)}>
            <View style={styles.buttonView}>
              <Text style={styles.buttonText}>Start</Text>
            </View>
          </TouchableOpacity>
        )}

        {status && status === "completed" ? (
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity onPress={() => handleAddProduct(item)}>
              <View style={styles.buttonView}>
                <Text style={styles.buttonText}>Add Product</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => handleAddService(item)}>
              <View style={styles.buttonView}>
                <Text style={styles.buttonText}>Add Service</Text>
              </View>
            </TouchableOpacity>
          </View>
        ) : null}
        {status && status === 'started' ? (
          <TouchableOpacity
            disabled={
              status && status === "started" ? false : true
            }
            onPress={() => handleComplete(item, index)}
          >
            <View style={styles.buttonView}>
              <Text style={styles.buttonText}>Complete</Text>
            </View>
          </TouchableOpacity>
        ) : null} */}

        {/* {status && status === "started" || status && status === "completed" ? (


          <TouchableOpacity onPress={() => handleAddNote(item)}>
            <View style={styles.buttonView}>
              <Text style={styles.buttonText}>Progress Note</Text>
            </View>
          </TouchableOpacity>


        ) : null} */}
        <View style={{ marginVertical: 10 * Common.WIDTH_DIVISOR }} />

      </View>}
    </View>)
  };

  const renderEmptyComponent = () => {
    return (
      <View>
        <Text style={{
          fontFamily: FONT_FAMILY,
          alignSelf: 'center',
          marginTop: 100,
          fontSize: 24,
          color: 'rgb(74,74,74)'
        }}
        >
          No results
        </Text>
      </View>
    )
  };

  return (
    <View style={{ Height: "auto", maxHeight: SCREEN_HEIGHT }}>
      <SafeAreaView >
        <Modal
          animationType="slide"
          transparent={true}
          visible={isNoteModalVisible}
          onRequestClose={() => {
            setIsNoteModalVisible(false);
          }}
        >
          <TouchableOpacity
            style={styles.modalContainer}
            activeOpacity={1}
            onPress={() => setIsNoteModalVisible(false)}
          >
            <TouchableWithoutFeedback>
              <View style={styles.modalContent}>
                <Text style={styles.modalTitle}>Add Note</Text>
                <TextInput
                  style={styles.noteInput}
                  multiline={true}
                  onChangeText={(text) => setSelectedItem({ ...selectedItem, note: text })}
                  value={selectedItem?.note || ''}
                  placeholder="Enter note"
                />
                <View style={styles.buttonsContainer}>
                  <TouchableOpacity
                    style={styles.saveButton}
                    onPress={() => {
                      handleAddedNote(selectedItem);
                      setIsNoteModalVisible(false);
                      setSelectedItem(null);
                    }}
                  >
                    <Text style={styles.buttonText}>Save</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </TouchableOpacity>
        </Modal>
        <FlatList
          style={{ backgroundColor: COLORS.GRAY_1 }}
          data={employeeEventData}
          renderItem={renderItem}
          keyExtractor={(item) => item.eventId}
          showsVerticalScrollIndicator={false}
          ListEmptyComponent={renderEmptyComponent}
          //下拉刷新
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
      </SafeAreaView>
    </View>
  );
};

export default AppointmentList;



const styles = StyleSheet.create({
  tinyLogo: {
    width: 100,
    marginTop: 5,
    height: 100,
  },
  buttonView: {
    backgroundColor: COLORS.MAIN,
    borderRadius: 22,
    padding: 3,
    marginLeft: 5,
  },

  subContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 2,
    alignContent: "center",
    alignItems: "center",
  },
  categoryHeader: {
    fontSize: 20,
    color: "gray",
    fontWeight: "bold",
  },
  categoryDetail: {
    fontSize: 18,
  },
  buttonText: {
    fontFamily: FONT_FAMILY,
    color: "white",
    fontSize: 15,
    marginHorizontal: 10,
  },

  buttonBG: {
    backgroundColor: COLORS.MAIN,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginHorizontal: 5,
    marginTop: 5,
    width: 150,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    paddingTop: 42,
    paddingBottom: 12,
    borderBottomColor: "#F6D9C4",
    borderBottomWidth: 2,
  },

  container: {
    width: SCREEN_WIDTH,
  },
  menuContainer: {
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
  },
  menuImage: {
    width: 25,
    height: 25,
    marginLeft: 25,
    paddingRight: 5,
    marginBottom: -5,
  },
  menuArrowImage: {
    width: 15,
    height: 25,
    marginLeft: 25,
    paddingRight: 5,
    marginBottom: -5,
    position: "absolute",
    right: 25,
  },
  titleText: {
    ...TEXT_STYLES.FONT_TYPE_5,

    ...{
      textAlign: "left",
      fontSize: 20,
    },
  },
  subTitleText2: {
    ...TEXT_STYLES.FONT_TYPE_5,
    ...{
      textAlign: "left",
      fontSize: 16,
    },
  },
  procutName: {
    ...TEXT_STYLES.FONT_TYPE_5,
    ...{
      textAlign: "left",
      fontSize: 18,
    },
  },
  subNameText: {
    ...TEXT_STYLES.FONT_TYPE_5,
    ...{
      textAlign: "left",
      width: 200,
    },
  },
  subTitleText: {
    ...TEXT_STYLES.FONT_TYPE_5,
    ...{
      textAlign: "left",
      width: 60,
    },
  },
  radioButtonRow: {
    marginHorizontal: 22,
    marginTop: 42,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  createNewAccountText: {
    color: COLORS.MAIN,
    fontSize: 16,
    textDecorationLine: "underline",
    textAlign: "center",
    marginTop: 40,
  },

  divider: {
    marginVertical: 10,
  },
  menuItem: {
    fontSize: 30,
    margin: 15,
    paddingLeft: 5,
    paddingTop: 5,
  },
  button: {
    padding: 24,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.MAIN,
    borderRadius: 24,
    margin: 8,
  },

  modalContainer: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "white",
    width: "80%",
    borderRadius: 10,
    padding: 20,
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  noteInput: {
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 5,
    minHeight: 100,
    textAlignVertical: "top",
    padding: 10,
    marginBottom: 20,
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  cancelButton: {
    backgroundColor: "red",
    borderRadius: 5,
    padding: 10,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: "green",
    borderRadius: 5,
    padding: 10,
  },

});