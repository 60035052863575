import React, { useState, useContext, useEffect } from "react";
import {
  View,
  TextInput,
  Text,
  StyleSheet,
  DeviceEventEmitter,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";

import {
  SCREEN_WIDTH,
  TEXT_STYLES,
  STYLES,
  COLORS,
  FONT_FAMILY,
} from "../Util/Styles";

import LoadingContext from "../Context/LoadingContext";
import UserContext from "../Context/UserContext";
import { RedRoundedButton } from "../SharedComponents/StyledButtons";
import { GrayRoundedTextInput } from "../SharedComponents/StyledTextInput";

import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

import config from "../Util/config";
import alert from '../Util/alert'

const Login = ({ navigation }) => {
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const [codedisable, setCodedisable] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [stayLoggedIn, setStayLoggedIn] = useState(true);
  const { user, setUser } = useContext(UserContext);
  console.log("stayLoggedIn", stayLoggedIn);


  const signInOnPress = async () => {
    if (userEmail.length == 0) {
      alert("Email cannot be empty");
      return;
    }
    if (password.length == 0) {
      alert("Verification Code cannot be empty");
      return;
    }
    const emailRegex = /\S+@\S+\.\S+/;

    if (!emailRegex.test(userEmail)) {
      alert("Please enter a valid Email");
      return;
    }

    showLoading();

    const payload = {
      email: userEmail,
      password,
    };

    try {
      await axios
        .post(config.apiurl + "/employee/loginWithCode", payload)
        .then((res) => {
          closeLoading();

          if (res.data.error) {
            alert(res.data.error);
            return;
          }
          if (stayLoggedIn) {
            try {
              AsyncStorage.setItem('acc', JSON.stringify(payload))
            } catch (error) {
              console.log(error);
            }
          }
          console.log(res.data)
          AsyncStorage.setItem("user", JSON.stringify(res.data)).catch(
            (err) => {
              console.log(err);
            }
          );

          setUser(res.data.data);

          navigation.navigate('Main')
          //触发绑定消息token事件
          // DeviceEventEmitter.emit('login')

        });
    } catch (error) {
      closeLoading();

      console.log(error);
    }
  };

  const getpassword = async () => {
    if (userEmail.length == 0) {
      alert("Email cannot be empty");
      return;
    }


    const emailRegex = /\S+@\S+\.\S+/;

    if (!emailRegex.test(userEmail)) {
      alert("Please enter a valid Email");
      return;
    }

    showLoading();


    const payload = {
      email: userEmail,
    };

    try {
      axios
        .post(config.apiurl + "/employee/sendLoginCode", payload)
        .then((res) => {
          closeLoading();
          if (res.data.error) {
            alert(res.data.error);
            return;
          }
          console.log(res)
          setCodedisable(true)
          alert(res.data);
        })
        .catch((err) => {
          closeLoading();
          console.log(err)
        })
    } catch (error) {
      closeLoading();
      console.log(error);
    }
  };

  return (
    <View style={styles.container}>
      <SafeAreaView>
        <Text style={styles.titleText}>Sign In</Text>

        <View style={styles.divider} />

        <GrayRoundedTextInput
          placeholder={"Email"}
          onChangeText={(text) => setUserEmail(text)}
        />

        <View style={styles.divider} />

        <TouchableOpacity onPress={() => getpassword()} disabled={codedisable}>
          <View style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: 15,
            marginBottom: 20,
          }}>
            <Text style={{ color: codedisable ? "gray" : "#3c4ae2" }}>{"Get Verification Code"}</Text>
          </View>
        </TouchableOpacity>

        <TextInput
          secureTextEntry={true}
          style={styles.secureInput}
          placeholder={"Verification Code"}
          placeholderTextColor="rgba(0,0,0,0.5)"
          onChangeText={(text) => setPassword(text)}
          autoCapitalize={'none'}
          autoCorrect={false}
        />

        <RedRoundedButton text={"Sign In"} onPress={() => signInOnPress()} />
        <RedRoundedButton text={"Back"} onPress={() => navigation.goBack()} />
      </SafeAreaView>
    </View >
  );
};


const styles = StyleSheet.create({
  container: {
    width: SCREEN_WIDTH,
  },

  titleText: {
    ...TEXT_STYLES.FONT_TYPE_6,
    ...{
      marginLeft: 24,
      textAlign: "left",
      marginRight: 6,
      marginTop: 24,
      marginBottom: 12,
    },
  },
  secureInput: {
    backgroundColor: "#EFEFEF",
    paddingVertical: 20,
    paddingHorizontal: 24,
    margin: 5,
    borderRadius: 48,
    marginHorizontal: 20,
  },
  radioButtonRow: {
    marginHorizontal: 22,
    marginTop: 42,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  createNewAccountText: {
    color: COLORS.MAIN,
    fontSize: 16,
    textDecorationLine: "underline",
    textAlign: "center",
    marginTop: 40,
  },

  divider: {
    marginVertical: 10,
  },
});

export default Login;
