import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
  TextInput,
  FlatList,
  ScrollView,
} from "react-native";
import Header from "../SharedComponents/Header";
import { COLORS, FONT_FAMILY, SCREEN_WIDTH, STYLES, TEXT_STYLES } from "../Util/Styles";
import UserContext from "../Context/UserContext";
import config from "../Util/config";
import axios from "axios";
import moment from "moment";
import Common from "../Util/common";

export default class UserDetailPage extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      merchandiseTotal: "",
      serviceTotal: "",
      orderMerchandiseItems: [],
      modalVisible: false,
      progressNotes: [],
    };
  }

  componentDidMount = () => {
    const { user, setUser } = this.context
    let postData = {
      _id: this.props.route.params.userId,
    }
    axios
      .post(`${config.apiurl}/user/getUserById`, postData)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data)
          if (res.data.length > 0) {
            this.setState(res.data[0])
            if (res.data[0].progressNotes) {
              this.setState({ progressNotes: res.data[0].progressNotes })
            }
          }
        }
      })
  };

  render() {
    return (
      <View style={styles.container}>
        <Header />
        <View style={{
          alignItems: "center"
        }}>
          <Text style={styles.titleText}>{this.props.route.params.customerName}</Text>

          <Text style={styles.subTitleText2}>
            {this.props.route.params.email}
          </Text>
          <Text style={styles.subTitleText2}>
            {this.props.route.params.phone}
          </Text>
        </View>
        <View style={STYLES.TAB_TITLE_DIVIDER} />
        <ScrollView>
          <View style={{
            marginVertical: 20,
            alignItems: "center"
          }}>
            <Text style={styles.titleText}>Account Information</Text>
          </View>
          <View style={styles.subContainer}>
            <Text style={styles.subTitleText2}>Customer Name: </Text>
            <Text>{this.state.name} </Text>
          </View>
          <View style={styles.subContainer}>
            <Text style={styles.subTitleText2}>Phone: </Text>
            <Text>{this.state.phone} </Text>
          </View>

          <View style={styles.subContainer}>
            <Text style={styles.subTitleText2}>Email: </Text>
            <Text>{this.state.email} </Text>
          </View>

          <View style={styles.subContainer}>
            <Text style={styles.subTitleText2}>Balance: </Text>
            <Text>${this.state.balance} </Text>
          </View>

          <View style={styles.subContainer}>
            <Text style={styles.subTitleText2}>Point: </Text>
            <Text>{this.state.points} </Text>
          </View>

          <View style={{ marginHorizontal: 20, flexDirection: "column" }}>
            <Text style={{ ...styles.subTitleText2, }}>Progress Notes: </Text>
            <FlatList
              data={this.state.progressNotes}
              renderItem={(item) => {
                console.log(item)
                return (
                  <View style={{ flexDirection: "column" }}>
                    <View style={{ alignSelf: "flex-start" }}>
                      <Text style={{ fontSize: 20 * Common.WIDTH_DIVISOR }}>
                        {item.item.notes}
                      </Text>
                    </View>
                    <View style={{ alignSelf: "flex-end" }}>
                      <Text style={{ fontSize: 15 * Common.WIDTH_DIVISOR }}>
                        {moment(item.item.createdAt).format("MMM DD YYYY, h:mm a")} by{" "}
                        {item.item.by}
                      </Text>
                    </View>
                  </View>
                );
              }}
              keyExtractor={(item) => item._id}
              showsVerticalScrollIndicator={false}
            />
          </View>




          <View style={styles.subContainer}>
            <Text style={styles.subTitleText2}>Notes: </Text>
            <Text>{this.state.Notes} </Text>

          </View>
          <TouchableOpacity style={{
            marginVertical: 20,
            alignItems: "center"
          }} onPress={() => {
            this.setState({ modalVisible: true })
          }}>
            <View style={{
              backgroundColor: COLORS.MAIN,
              borderRadius: 10,
              alignItems: "center",
              width: 120,
              alignContent: "center",
              // marginLeft: 100,
              padding: 3,
            }}>
              <Text style={{
                fontFamily: FONT_FAMILY,
                color: "white",
                fontSize: 15,
                marginHorizontal: 10,
              }}>Edit Notes</Text>
            </View>
          </TouchableOpacity>
        </ScrollView >
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            this.setState({ modalVisible: !this.state.modalVisible });
          }}
        >
          <TouchableWithoutFeedback onPress={() => {
            this.setState({ modalVisible: !this.state.modalVisible });
          }}>
            <View style={{
              flex: 1,
              alignItems: "center",
              backgroundColor: "rgba(220,220,220,0.5)"
            }}>
              <View style={styles.modalTextView}>
                <TextInput
                  style={styles.textInput}
                  autoFocus={true}
                  multiline={true}
                  numberOfLines={4}
                  onChangeText={(text) => {
                    this.setState({ Notes: text })
                  }}
                  value={this.state.Notes}
                />

                <TouchableOpacity onPress={() => {
                  axios
                    .post(`${config.apiurl}/user/editProfile`, {
                      _id: this.state._id,
                      Notes: this.state.Notes
                    })
                    .then(res => {
                      if (res.status === 200) {
                        this.setState({ modalVisible: !this.state.modalVisible })
                      } else {
                        console.log(res)
                      }
                    })
                    .catch(err => {
                      console.log(err.response)
                    })
                }}>
                  <View style={styles.saveButton}>
                    <Text style={{
                      fontFamily: FONT_FAMILY,
                      color: "white",
                      fontSize: 20,
                    }}>Save</Text>
                  </View>
                </TouchableOpacity>

              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>

      </View >
    );
  }
}

const styles = StyleSheet.create({
  titleText: {
    ...TEXT_STYLES.FONT_TYPE_5,
    ...{
      textAlign: "left",
      fontSize: 20,
    },
  },
  subTitleText2: {
    ...TEXT_STYLES.FONT_TYPE_5,
    ...{
      textAlign: "left",
      fontSize: 16,
      // fontWeight:"bold"
    },
  },
  modalTextView: {
    marginTop: 70,
    backgroundColor: "white",
    borderRadius: 10,
    width: SCREEN_WIDTH - 40,
    height: 300,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  textInput: {
    marginTop: 20,
    width: SCREEN_WIDTH - 60,
    height: 200,
    borderRadius: 20,
    marginHorizontal: 20,
    fontFamily: FONT_FAMILY,
    fontSize: 20,
    color: COLORS.GRAY_4,
    backgroundColor: COLORS.TRANSPARENT_GRAY_2,
  },
  saveButton: {
    backgroundColor: COLORS.MAIN,
    borderRadius: 20,
    marginTop: 20,
    height: 50,
    width: 100,
    justifyContent: "center",
    alignItems: "center"
  },
  container: {
    flex: 1,
  },

  subContainer: {
    marginLeft: 20,
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 2,
    alignContent: "center",
    alignItems: "center",
  },
});
