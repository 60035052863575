export default function timeTransfer(time) {
  let time24 = time
  let time12 = ""
  let hour = parseInt(time24.split(":")[0])
  let minute = time24.split(":")[1]
  let ampm = hour >= 12 ? "PM" : "AM"

  if (hour == 0) {
    time12 = "12:" + minute + " " + ampm
  } else if (hour > 12) {
    time12 = (hour - 12) + ":" + minute + " " + ampm
  } else {
    time12 = hour + ":" + minute + " " + ampm
  }
  return time12
}