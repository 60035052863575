import React, { useState, useContext, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  FlatList,
  Image,
  DeviceEventEmitter
} from 'react-native';
import Header from '../SharedComponents/Header';
import { COLORS, FONT_FAMILY, STYLES } from '../Util/Styles';
import Moment from 'moment';
import axios from 'axios';
import config from "../Util/config";
import UserContext from "../Context/UserContext";

export default function Message(props) {
  const [data, setData] = useState([]);
  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData();
    const refreshListener = DeviceEventEmitter.addListener(
      'refreshMessage',
      () => {
        loadData();
      }
    );
    return () => {
      refreshListener.remove();
    };
  }, []);

  const loadData = () => {
    setIsLoading(true);
    axios
      .get(config.apiurl + '/employee/getMessages?id=' + user._id)
      .then((res) => {
        if (res.hasOwnProperty('error')) {
          setIsLoading(false);
        } else {
          setData(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const renderItem = ({ item }) => {
    return (
      <View style={{ backgroundColor: '#fdfeff' }} key={item._id}>
        <View
          style={{
            flexDirection: 'row',
            marginTop: 20,
            lineHeight: 16,
            marginLeft: 17
          }}
        >
          <Image
            style={{
              // marginTop: 20,
            }}
            source={
              item.flag == 'unread'
                ? require('../../resource/Message/MessageHightLight.png')
                : require('../../resource/Message/MessageGray.png')
            }
          />
          <Text
            style={{
              fontSize: 12,
              lineHeight: 16,
              marginLeft: 8,
              fontWeight: 'bold',
              color:
                item.flag == 'unread'
                  ? 'rgba(0, 123, 195, 0.54)'
                  : 'rgba(0, 0, 0, 0.54)'
            }}
          >
            Messager{' '}
          </Text>
          <Text
            style={{
              fontSize: 12,
              marginLeft: 8,
              color: 'rgba(0, 0, 0, 0.54)'
            }}
          >
            {Moment(item.createTime).format('YYYY-MM-DD hh:mm a')}
          </Text>
        </View>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 20,
            marginLeft: 17,
            color: 'rgba(0, 0, 0, 0.54)'
          }}
        >
          {item.message}
        </Text>
        <View
          style={{
            height: 0.6,
            opacity: 0.26,
            marginVertical: 16,
            backgroundColor: '#000000'
          }}
        />
      </View>
    );
  };

  const renderEmptyComponent = () => {
    return (
      <View>
        <Text
          style={{
            fontFamily: FONT_FAMILY,
            alignSelf: 'center',
            marginTop: 100,
            fontSize: 24,
            color: 'rgb(74,74,74)'
          }}
        >
          No results
        </Text>
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <Header />
       <Text style={STYLES.TAB_TITLE}>Message</Text>
      <View style={STYLES.TAB_TITLE_DIVIDER} />
      <FlatList
        style={{ backgroundColor: COLORS.GRAY_1 }}
        data={data}
        renderItem={renderItem}
        keyExtractor={(item) => item._id}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={renderEmptyComponent}

        //下拉刷新
        refreshing={isLoading}
        onRefresh={() => {
          loadData(); //下拉刷新加载数据
        }}

      /> 
    </SafeAreaView>
  )
}



const styles = StyleSheet.create({
  avatar: {
    width: 70,
    height: 70,
    marginLeft: 20,
    marginRight: 16,
    borderRadius: 70,
    backgroundColor: COLORS.MAIN,
    marginTop: 36
  },

  name: {
    fontFamily: FONT_FAMILY,
    fontSize: 24,
    lineHeight: 29,
    color: COLORS.GRAY_4,
    marginTop: 48,
    marginRight: 20
  },

  email: {
    fontSize: 16,
    lineHeight: 19,
    marginRight: 20
  },

  row: {
    paddingVertical: 24,
    paddingLeft: 10,
    paddingRight: 22,
    flexDirection: "row",
    marginHorizontal: 20,
    alignContent: "center"
  },

  rowText: {
    flex: 1,
    fontSize: 16,
    lineHeight: 19
  },

  icon: {
    marginRight: 12
  },

  content: {
    flex: 1
  },

  container: {
    flex: 1,
  }
})
