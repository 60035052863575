import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  TouchableWithoutFeedback,
  TextInput,
  Modal,
  TouchableOpacity,
  KeyboardAvoidingView
} from "react-native";
import {
  SCREEN_WIDTH,
  TEXT_STYLES,
  COLORS,
  FONT_FAMILY,
} from "../Util/Styles";
import moment from "moment";
import Header from '../SharedComponents/Header'
import timeTransfer from "../Util/timeTransfer";
import Common from "../Util/common";
import alert from '../Util/alert'
import { useNavigation } from '@react-navigation/native';
import axios from "axios";
import config from "../Util/config";

const AppointmentDetail = (props) => {
  const navigation = useNavigation();
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [progressNote, setProgressNote] = useState("");
  const [noteType, setNoteType] = useState("");
  const {
    customerName,
    day,
    endTime,
    room,
    selectedService,
    startTime,
    type,
    note,
    userId
  } = props.route.params.item;

  const [showNote, setShowNote] = useState("");
  useEffect(() => {
    setShowNote(note)
  }, []);


  const handleAddNote = (type) => {
    setNoteType(type)
    if (type == "note") {
      setProgressNote(note)
    } else if (type == "progress") {
      setProgressNote("")
    }
    setIsNoteModalVisible(true);
  }
  const handleAddedNote = () => {
    console.log(props.route.params.item)
    if (noteType == "progress") {
      let item = props.route.params.item
      const postData = {
        userId: item.userId._id,
        notes: progressNote,
        name: item.userId.name
      }
      console.log(postData)

      axios
        .post(`${config.apiurl}/employee/addProgressNote`, postData)
        .then((res) => {
          console.log(res)
          alert(res.data.message)
        })
    } else if (noteType == "note") {
      let item = props.route.params.item

      const postData = {
        _id: item.eventId,
        note: progressNote,
      }
      axios
        .post(`${config.apiurl}/event/updateEventNote`, postData)
        .then((res) => {
          console.log(res.data.event.message)
          setShowNote(res.data.event.message)
          alert(res.data.message)
        })
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header />
      <View style={{ backgroundColor: COLORS.MAIN, marginBottom: 10, padding: 15 }}>
        <Text style={{ color: "white", alignSelf: "center", fontSize: 40, fontWeight: "bold" }}>
          Detail
        </Text>
      </View>

      <View>
        <View style={styles.subContainer}>
          <Text style={styles.categoryHeader}>Time</Text>
          <Text style={styles.categoryDetail}>
            {timeTransfer(startTime)} - {timeTransfer(endTime)}
          </Text>
          <Text style={styles.categoryDetail}>
            {day}
          </Text>
        </View>
        {type === 'blocked' ?
          <View style={styles.subContainer}>
            <Text style={styles.categoryHeader}>Type</Text>
            <Text style={styles.categoryDetail}>Blocked Time</Text>
          </View> :
          <View>
            <View style={styles.subContainer}>
              <Text style={styles.categoryHeader}>Service Detail</Text>
              <Text style={styles.categoryDetail}>{selectedService}</Text>
              <Text style={styles.categoryDetail}>{room}</Text>
            </View>
            <View style={styles.subContainer}>
              <Text style={styles.categoryHeader}>Customer Name</Text>
              <TouchableOpacity onPress={() => {
                navigation.navigate('userDetail', {
                  userId: userId._id,
                  customerName: userId.name,
                  phone: userId.phone,
                  email: userId.email
                })
              }}>
                <Text style={{ textDecorationLine: "underline", color: "#4169E1" }}>{customerName}</Text>
              </TouchableOpacity>
            </View>
          </View>
        }
        <View style={styles.subContainer}>
          <Text style={styles.categoryHeader}>Note</Text>
          <Text style={styles.categoryDetail}>{showNote}</Text>
        </View>
        <TouchableOpacity onPress={() => handleAddNote("note")}>
          <View style={{
            width: 200 * Common.WIDTH_DIVISOR,
            height: 30 * Common.WIDTH_DIVISOR,
            backgroundColor: COLORS.MAIN,
            marginTop: 20 * Common.WIDTH_DIVISOR,
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 10 * Common.WIDTH_DIVISOR,
          }}>
            <Text style={{
              fontFamily: FONT_FAMILY,
              color: "white",
              fontSize: 15,
              // marginHorizontal: 10,
            }}>Edit Appointment Note</Text>
          </View>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => handleAddNote("progress")}>
          <View style={{
            width: 150 * Common.WIDTH_DIVISOR,
            height: 30 * Common.WIDTH_DIVISOR,
            marginTop: 20 * Common.WIDTH_DIVISOR,
            borderRadius: 10 * Common.WIDTH_DIVISOR,
            backgroundColor: COLORS.MAIN,
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <Text style={{
              fontFamily: FONT_FAMILY,
              color: "white",
              fontSize: 15,
            }}>Add Progress Note</Text>
          </View>
        </TouchableOpacity>
      </View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={isNoteModalVisible}
        onRequestClose={() => {
          setIsNoteModalVisible(false);
        }}
      >
        <TouchableOpacity
          style={styles.modalContainer}
          activeOpacity={1}
          onPress={() => setIsNoteModalVisible(false)}
        >
          <KeyboardAvoidingView style={{flex:0.5, flexDirection: 'column',justifyContent: 'center'}} behavior='padding' enabled >

          <TouchableWithoutFeedback>

            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>{noteType == "progress" ? "Add Progress Note" : "Edit Appointment Note"} </Text>
              <TextInput
                style={styles.noteInput}
                multiline={true}
                onChangeText={(text) => { setProgressNote(text) }}
                value={progressNote}
                placeholder="Enter note"
              />
              <View style={styles.buttonsContainer}>
                <TouchableOpacity
                  style={styles.saveButton}
                  onPress={() => {
                    handleAddedNote();
                    setIsNoteModalVisible(false);
                  }}
                >
                  <Text style={styles.buttonText}>Save</Text>
                </TouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
          </KeyboardAvoidingView>

        </TouchableOpacity>
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  subContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 20,
    alignContent: "center",
    alignItems: "center",
  },
  categoryHeader: {
    fontSize: 20,
    color: "gray",
    fontWeight: "bold",
  },
  categoryDetail: {
    fontSize: 20 * Common.WIDTH_DIVISOR,
  },
  buttonText: {
    fontFamily: FONT_FAMILY,
    color: "white",
    fontSize: 28,
  },

  buttonBG: {
    backgroundColor: COLORS.MAIN,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginHorizontal: 5,
    marginTop: 5,
    width: 150,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    paddingTop: 42,
    paddingBottom: 12,
    borderBottomColor: "#F6D9C4",
    borderBottomWidth: 2,
  },

  container: {
    width: SCREEN_WIDTH,
  },
  menuContainer: {
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
  },
  menuImage: {
    width: 25,
    height: 25,
    marginLeft: 25,
    paddingRight: 5,
    marginBottom: -5,
  },
  menuArrowImage: {
    width: 15,
    height: 25,
    marginLeft: 25,
    paddingRight: 5,
    marginBottom: -5,
    position: "absolute",
    right: 25,
  },
  titleText: {
    ...TEXT_STYLES.FONT_TYPE_6,

    ...{
      marginLeft: 24,
      textAlign: "left",
      marginRight: 6,
      marginTop: 24,
      marginBottom: 12,
    },
  },
  subTitleText: {
    ...TEXT_STYLES.FONT_TYPE_5,
    ...{
      marginLeft: 24,
      textAlign: "left",
      marginRight: 6,
      marginTop: 24,
      marginBottom: 12,
    },
  },
  radioButtonRow: {
    marginHorizontal: 22,
    marginTop: 42,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  createNewAccountText: {
    color: COLORS.MAIN,
    fontSize: 16,
    textDecorationLine: "underline",
    textAlign: "center",
    marginTop: 40,
  },

  divider: {
    marginVertical: 10,
  },
  menuItem: {
    fontSize: 30,
    margin: 15,
    paddingLeft: 5,
    paddingTop: 5,
  },
  button: {
    padding: 24,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.MAIN,
    borderRadius: 24,
    margin: 8,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "white",
    width: "80%",
    borderRadius: 10,
    padding: 20,
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  noteInput: {
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 5,
    minHeight: 100,
    textAlignVertical: "top",
    padding: 10,
    marginBottom: 20,
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  saveButton: {
    backgroundColor: COLORS.MAIN,
    borderRadius: 20,
    marginTop: 20,
    height: 50,
    width: 100,
    justifyContent: "center",
    alignItems: "center"
  },
});
export default AppointmentDetail;
