import React from 'react'
import { TextInput, StyleSheet } from 'react-native'

class GrayRoundedTextInput extends React.Component {
    render() {
        return <TextInput
            placeholderTextColor="rgba(0,0,0,0.5)" 
            autoCapitalize={'none'}
            autoCorrect={false}
            style={styles.type1} {...this.props} />
    }
}

const styles = StyleSheet.create({
    type1: {
        backgroundColor: "#EFEFEF",
        paddingVertical: 20,
        paddingHorizontal: 24,
        borderRadius: 48,
        marginHorizontal: 20
    },
})

export { GrayRoundedTextInput }